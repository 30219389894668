import React from 'react';
import {Fragment} from 'react';

// NAVIGATION BAR CONTENT
export const navigationBar = {
  links: [
    {
      path: '/',
      key: 'home',
      value: 'HOME',
    },
    {
      path: undefined,
      key: 'solutions',
      value: 'SOLUTIONS',
      sublinks: [
        {
          path: '/solutions/hospitality',
          value: 'HOSPITALITY',
          key: 'HOSPITALITY',
        },
        {
          path: '/solutions/mdu',
          value: 'MDU',
          key: 'mdu',
        },
        {
          path: '/solutions/commercial',
          value: 'COMMERCIAL',
          key: 'commercial',
        },
        {
          path: '/solutions/residential',
          value: 'RESIDENTIAL',
          key: 'residential',
        },
      ],
    },
    {
      path: undefined,
      key: 'partners',
      value: 'PARTNERS',
      sublinks: [
        {
          path: '/partners/bodhi',
          value: 'BODHI',
          key: 'bodhi',
        },
        {
          path: '/partners/crestron',
          value: 'CRESTRON',
          key: 'crestron',
        },
        {
          path: '/partners/crestron-home',
          value: 'CRESTRON HOME',
          key: 'crestronHome',
        },
        {
          path: '/partners/lutron',
          value: 'LUTRON',
          key: 'lutron',
        },
        {
          path: '/partners/rako',
          value: 'RAKO',
          key: 'rako',
        },
        {
          path: '/partners/silent-gliss',
          value: 'SILENT GLISS',
          key: 'silentGliss',
        },
      ],
    },
    {
      path: undefined,
      key: 'projects',
      value: 'CASE STUDIES',
      sublinks: [
        {
          path: '/projects/the-daytona',
          key: 'theDaytona',
          value: 'THE DAYTONA',
        },
        {
          path: '/projects/isleworth-residences',
          key: 'isleworthResidences',
          value: 'ISLEWORTH RESIDENCES',
        },
        {
          path: '/projects/nexus-club',
          key: 'nexusClub',
          value: 'NEXUS CLUB',
        },
        {
          path: '/projects/art-ovation-hotel',
          key: 'artOvation',
          value: 'ART OVATION',
        },
      ],
    },
    {
      path: 'https://news.gobodhi.com',
      key: 'news',
      value: 'NEWS',
    },
    {
      path: '/contact',
      key: 'contact',
      value: 'CONTACT',
    },
    {
      path: undefined,
      key: 'dashboard',
      value: 'DASHBOARD',
      sublinks: [
        {
          path: process.env.GATSBY_BODHI_APP_URL,
          key: 'artOvation',
          value: 'BODHI APP',
        },
      ],
    },
    {
      className: 'isSolid--true',
      valueClassName: 'isSolid--true',
      path: '#request-demo',
      key: 'requestDemo',
      value: 'REQUEST DEMO',
    },
  ],
};

// FOOTER CONTENT
export const footer = {
  brandImagePath: '/images/shared-logo-bodhi-white.png',
  contacts: [
    {
      label: 'Privacy Policy',
      url: '/documents/privacy-policy.html',
      value: '',
    },
    {
      label: 'Terms of Service',
      url: '/terms-of-service',
      value: '',
    },
    {
      label: 'Warranty',
      url: '/documents/warranty.pdf',
      value: '',
    },
    {
      label: 'Phone:',
      url: 'tel:8004670717',
      value: '800-467-0717',
    },
    {
      label: 'Email:',
      url: 'mailto:info@gobodhi.com',
      value: 'info@gobodhi.com',
    },
  ],
  socialMedias: [
    {
      iconName: 'linkedin-brands',
      url: 'https://www.linkedin.com/company/75721742/',
    },
  ],
  copyright: `© ${new Date().getFullYear()} Software Development, LLC`,
};

// REQUEST SECTION
export const request = {
  // PROJECT SLIDE
  projectType: {
    title: 'Project Type',
    description:
      'Just answer a few simple questions so we can personalize the right experience for you.',
    options: [
      {
        displayValue: 'Commercial',
        imagePath: '/images/request-section-commercial.jpg',
        value: 'commercial',
      },
      {
        displayValue: 'Hospitality',
        imagePath: '/images/request-section-hospitality.jpg',
        value: 'hospitality',
      },
      {
        displayValue: 'Residential',
        imagePath: '/images/request-section-residential.jpg',
        value: 'residential',
      },
      {
        displayValue: 'MDU',
        imagePath: '/images/request-section-mdu.jpg',
        value: 'mdu',
      },
    ],
  },
  // PROFESSION SLIDE
  profession: {
    title: 'I am a...',
    description: '',
    options: [
      {
        displayValue: 'Owner',
        value: 'owner',
      },
      {
        displayValue: 'Developer',
        value: 'developer',
      },
      {
        displayValue: 'Architect',
        value: 'architect',
      },
      {
        displayValue: 'Designer',
        value: 'designer',
      },
      {
        displayValue: 'Systems Integrator',
        value: 'systemsIntegrator',
      },
      {
        displayValue: 'Other',
        value: 'other',
      },
    ],
  },
  // CONTACT SLIDE
  contact: {
    title: 'Contact Information',
    description: '',
  },
  // LOCATION SLIDE
  location: {
    title: 'Location',
    description: '',
  },
  // CONFIRM SLIDE
  confirm: {
    title: 'Thank You',
    message: '',
  },
};

// HOME PAGE CONTENT
export const home = {
  // INTRO SECTION
  intro: {
    carouselSlides: [
      // PRIMARY
      {
        buildingManagementIdentifier: true,
        defaultBackgroundImagePath:
          '/images/home-page-intro-section-default-background-primary.jpg',
        description:
          'Fast, effective, monitoring and control of building systems using IoT technology',
        logoImagePath: undefined,
        smallBackgroundImagePath: '/images/home-page-intro-section-small-background-primary.jpg',
        position: 'center',
        hasButton: false,
        color: '#000',
        title: (
          <Fragment>
            Building Operation
            <br />
            Simplified
          </Fragment>
        ),
        mobileTitle: (
          <Fragment>
            Building
            <br />
            Operation
            <br />
            Simplified
          </Fragment>
        ),
      },
      // SECONDARY
      // {
      //   defaultBackgroundImagePath:
      //     '/images/home-page-intro-section-default-background-secondary.jpg',
      //   description:
      //     'Intuitive, comprehensive cloud-based building operation for corporate, hospitality, education, healthcare and residential with fast, effective monitoring and control.',
      //   logoImagePath: undefined,
      //   smallBackgroundImagePath: '/images/home-page-intro-section-small-background-initial.jpg',
      //   position: 'center',
      //   title: (
      //     <Fragment>
      //       Simplify your <br /> building operation
      //     </Fragment>
      //   ),
      // },
      // BODHI SENSORS
      {
        bodhiSensorsIdentifier: true,
        buttonText: 'Learn More',
        buttonId: 'bodhi-btn',
        defaultBackgroundImagePath:
          '/images/home-page-intro-section-default-background-bodhi-sensors.jpg',
        boldDescription: 'Building technology at a fraction of the cost: ',
        description: ' The control & operation needed in any property',
        // logoImagePath:
        //   '/images/home-page-intro-section-logo-bodhi-wireless-sensors-and-devices.png',
        path: '/partners/bodhi',
        smallBackgroundImagePath:
          '/images/home-page-intro-section-default-background-bodhi-sensors-small.jpg',
        rowHeading: false,
        columnHeading: false,
        hasButton: true,
        color: '#fff',
        title: (
          <Fragment>
            Bodhi Wireless
            <br />
            Sensors and Devices
          </Fragment>
        ),
      },
      // CRESTRON BODHI CLOUD
      {
        crestronBodhiCloudIdentifier: true,
        buttonText: 'Learn More',
        buttonId: 'learn-more-btn',
        defaultBackgroundImagePath:
          '/images/home-page-intro-section-default-background-crestron.jpg',
        boldDescription: 'Match made in the cloud: ',
        description: ' Guest centric control paired with comprehensive management',
        logoImagePath: '/images/crestron-logo-dark.webp',
        path: '/partners/crestron',
        smallBackgroundImagePath: '/images/home-page-intro-section-small-background-crestron.jpg',
        title: 'Bodhi',
        rowHeading: true,
        columnHeading: false,
        hasButton: true,
        color: '#000',
      },
      // CRESTRON HOME BODHI
      {
        crestronHomeBodhiIdentifier: true,
        buttonText: 'Learn More',
        buttonId: 'learn-more-btn',
        defaultBackgroundImagePath:
          '/images/home-page-intro-section-default-background-crestron-home.jpg',
        boldDescription: 'Crestron at its most intuitive: ',
        description: ' Simplified operation of guest rooms and residences',
        logoImagePath: '/images/crestron-home-os.png',
        path: '/partners/crestron-home',
        smallBackgroundImagePath:
          '/images/home-page-intro-section-default-background-crestron-home-small.jpg',
        title: 'Bodhi',
        rowHeading: false,
        columnHeading: true,
        hasButton: true,
        color: '#000',
      },
      // LUTRON BODHI
      {
        lutronBodhiIdentifier: true,
        buttonText: 'Learn More',
        buttonId: 'learn-more-btn',
        defaultBackgroundImagePath: '/images/home-page-intro-section-default-background-lutron.jpg',
        boldDescription: 'Premium hospitality lighting: ',
        description: 'Cloud-based and budget friendly',
        logoImagePath: '/images/lutron-logo-dark.png',
        path: '/partners/lutron',
        smallBackgroundImagePath: '/images/home-page-intro-section-small-background-lutron.jpg',
        title: 'Bodhi',
        rowHeading: true,
        columnHeading: false,
        hasButton: true,
        increaseLogoSize: true,
        color: '#000',
      },
      // RAKO
      {
        rakoBodhiIdentifier: true,
        buttonText: 'Learn More',
        buttonId: 'learn-more-btn',
        defaultBackgroundImagePath: '/images/home-page-intro-section-default-background-rako.png',
        boldDescription: '',
        description: 'Simple, flexible, and intuitive lighting control',
        logoImagePath: '/images/rako-logo-dark.png',
        path: '/partners/rako',
        smallBackgroundImagePath: '/images/home-page-intro-section-small-background-rako.png',
        title: 'Bodhi',
        rowHeading: true,
        columnHeading: false,
        hasButton: true,
        increaseLogoSize: true,
        color: '#000',
      },
      // SILENT GLISS
      {
        silentGlissBodhiIdentifier: true,
        buttonText: 'Learn More',
        buttonId: 'learn-more-btn',
        defaultBackgroundImagePath: '/images/silent-gliss-main-slide-content.webp',
        boldDescription: '',
        description: 'Smart Shading for your comfort and security',
        logoImagePath: '/images/silent-gliss-logo.png',
        path: '/partners/silent-gliss',
        smallBackgroundImagePath: '/images/silent-gliss-main-slide-content.webp',
        title: 'Bodhi',
        rowHeading: true,
        columnHeading: false,
        hasButton: true,
        increaseLogoSize: true,
        color: '#000',
      },
    ],
  },
  // MEET SECTION
  meet: {
    title: 'Meet Bodhi',
    description: [
      'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all of the technology on your property from a single interface — from lighting, climate, water, electrical metering, audio/visual and other building technologies.',
      'Best of all, it’s so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.',
    ],
    buttonText: 'Watch Video',
  },
  // CORE FEATURES SECTION
  coreComponents: {
    title: 'Core Features',
    items: [
      // DASHBOARD
      {
        description:
          'Designed for real people such as property managers, homeowners and management staff, not just engineers.',
        imagePath: '/images/home-page-features-section-dashboard.jpg',
        value: 'Dashboard',
        bulletPoints: [
          'Provides high-level overviews that drill down to individual devices',
          'Quickly select any device or system in any room or area',
          'Control that device, set up alerts, and view analytics',
        ],
      },
      // SCHEDULING
      {
        description:
          'Real time calendar that you can use to recall the perfect mood for dinner in the restaurant, set the stage for a Sunday pool party, or set up any preset or event you can imagine.',
        imagePath: '/images/home-page-features-section-scheduling.jpg',
        value: 'Scheduling',
        bulletPoints: [
          'Simply choose starting and ending dates and times',
          'Specify actions for devices, groups, presets or scenes ',
          'Schedule one-time events or repeat by day, week, month or year',
          'Automate via standard or astronomical time',
        ],
      },
      // ALERTS
      {
        description:
          'Lets you and appropriate staff know when something happens that might compromise your property.',
        imagePath: '/images/home-page-features-section-alerts.jpg',
        value: 'Alerts',
        bulletPoints: [
          'Triggers SMS text messages and email alerts  when a device operates outside its specified tolerances',
          'Can alert you of open doors or windows, water leaks or unusual electrical use',
          'Set up the alerts you need from within the dashboard',
        ],
      },
      // TROUBLE TICKETS
      {
        description: 'Helps you manage problems and track them through their resolution.',
        imagePath: '/images/home-page-features-section-trouble-tickets.jpg',
        value: 'Trouble Tickets',
        bulletPoints: [
          'Logs all alerts and steps toward resolution.',
          'Access from the dashboard or analytics package.',
        ],
      },
      // PREDICTIVE MAINTENANCE
      {
        description:
          'Predicts the potential failure of key systems on your property, then notifies staff and vendors to perform preventative maintenance or repairs.',
        imagePath: '/images/home-page-features-section-predictive-maintenance.jpg',
        value: 'Predictive Maintenance',
        bulletPoints: [
          'Tracks the age and hours of use of key systems and components.',
          'Measures adherence to each manufacturer’s specifications.',
          'Logs  crucial measurements for each system and monitors how they track with changing conditions.',
        ],
      },
      // ANALYTICS
      {
        description:
          'An easy-to-use package that keeps you informed about the use of your property and technology.',
        imagePath: '/images/home-page-features-section-analytics.jpg',
        value: 'Analytics',
        bulletPoints: [
          'Simple charts and graphs help you see at a glance what’s going on',
          'Graphs usage trends for rooms, spaces, systems and components',
          'Summaries display on the dashboard',
          'Detailed analysis via a customizable reporting package',
          'Can create state, federal and corporate energy utilization compliance reports',
        ],
      },
      // GUEST APP
      {
        description: 'The power of Bodhi in a browser based app for hotel guests.',
        imagePath: '/images/home-page-features-section-guest-app.jpg',
        value: 'Guest App',
        bulletPoints: [
          'Browser based, nothing to install',
          'Keyless room entry',
          'Control of lights, climate, shades, entertainment systems',
          'Call the valet, housekeeping, book reservations',
          'Chat with front desk or concierge ',
          'Customize with your branding and web-based information',
          'Integrate with your existing app or simply share link',
          'Can automatically populate with guest names and room numbers',
        ],
      },
      // SECURITY
      {
        description: 'Keeps your property and its systems secure from ill-intentioned people.',
        imagePath: '/images/home-page-features-section-security.jpg',
        value: 'Security',
        bulletPoints: [
          'Granular control levels for access, alerts, and configuration',
          'Can utilize role-based permissions established by your active directory server',
          'Built on Microsoft Azure',
        ],
      },
      // INTEGRATIONS
      {
        description: 'Enhance your guest experience through a wide range of technology',
        imagePath: '/images/home-page-features-section-integrations.jpg',
        value: 'Integrations',
        bulletPoints: [
          'Fully compatible with all Crestron products and the systems they control (including most AV, HVAC, fire and security systems)',
          'Fully compatible with all Lutron platforms, including Caséta',
          'Manages Amazon Alexa devices for voice control ',
          'Manages and controls a range of Bodhi wireless devices',
          'Integrates with most property management platforms to trigger guest room ‘welcome’ and ‘eco’ modes based on occupancy',
        ],
      },
    ],
  },
  // CASE STUDIES SECTION
  projects: {
    linkText: 'Learn More',
    title: 'CASE STUDIES',
    items: [
      // THE DAYTONA HOTEL
      {
        cardImagePath: '/images/home-page-projects-section-card-the-daytona-hotel.jpg',
        description:
          'Operation, scheduling and control of lighting, music, and digital signage systems in the meeting center, restaurant, and common areas, including a huge outdoor LED display at the entrance.',
        thumbnailImagePath: '/images/home-page-projects-section-thumbnail-the-daytona-hotel.jpg',
        title: 'The Daytona Hotel',
        url: '/projects/the-daytona',
      },
      // ISLEWORTH RESIDENCES
      {
        cardImagePath: '/images/home-page-projects-section-card-isleworth-residences.jpg',
        description:
          'Simplified setup, scheduling, control and maintenance of an extensive Crestron lighting system in a very large luxury home',
        thumbnailImagePath: '/images/home-page-projects-section-thumbnail-isleworth-residences.jpg',
        title: 'Home in Isleworth',
        url: '/projects/isleworth-residences',
      },
      // NEXUS CLUB NEW YORK
      {
        cardImagePath: '/images/home-page-projects-section-card-nexus-club-new-york.jpg',
        description:
          'Simplified monitoring, control and scheduling of Crestron climate, lighting, and music systems throughout the clubs, including the spa, restaurants, bars, event spaces and other public areas.',
        thumbnailImagePath: '/images/home-page-projects-section-thumbnail-nexus-club-new-york.jpg',
        title: 'Nexus Club New York',
        url: '/projects/nexus-club',
      },
      // ART OVATION HOTEL
      {
        cardImagePath: '/images/home-page-projects-section-card-art-ovation-hotel.jpg',
        description:
          'Monitoring, control and scheduling of Crestron lighting, music and digital signage systems throughout the common areas and meeting center, with instant customization by managers and staff.',
        thumbnailImagePath: '/images/home-page-projects-section-thumbnail-art-ovation-hotel.jpg',
        title: 'Art Ovation Hotel',
        url: '/projects/art-ovation-hotel',
      },
    ],
  },
  // PARTNERS
  partners: {
    title: 'Industry-leading Solutions',
    description:
      'Bodhi integrates deeply with enterprise partners such as Crestron and Lutron and brings a new level of service to the hospitality, commercial, and residential industries.',
    linkText: 'Learn More',
    items: [
      // CRESTRON
      {
        description:
          'Crestron engineers high-tech solutions for every market. From customized luxury smart home automation to scalable out of the box collaborative tools that will guarantee reliable, easy to use solutions. ',
        imagePath: '/images/home-page-solutions-section-crestron.png',
        linkUrl: '/partners/crestron',
        logoAndTextImagePath: '/images/home-page-solutions-section-logo-and-text-crestron.png',
        logoImagePath: '/images/home-page-solutions-section-logo-crestron.png',
        title: 'Crestron',
      },
      // BODHI
      {
        description:
          'Bodhi products are designed to work together. Whether you are away for business or on holiday, or just simply at work down the street, you have full home control and access from any location.',
        imagePath: '/images/home-page-solutions-section-bodhi.png',
        linkUrl: '/partners/bodhi',
        logoAndTextImagePath: '/images/home-page-solutions-section-logo-and-text-bodhi.png',
        logoImagePath: '/images/home-page-solutions-section-logo-bodhi.png',
        title: 'Bodhi',
      },
      // LUTRON
      {
        description:
          'Lutron designs and manufactures more than 15,000 energy-saving products, from dimmers for the home to light management systems for  buildings. Lutron is committed to innovation.',
        imagePath: '/images/home-page-solutions-section-lutron.png',
        linkUrl: '/partners/lutron',
        logoAndTextImagePath: '/images/home-page-solutions-section-logo-and-text-lutron.png',
        logoImagePath: '/images/home-page-solutions-section-logo-lutron.png',
        title: 'Lutron',
      },
    ],
  },
  // NEW
};

// PARTNER PAGE CONTENT
export const partner = {
  // CRESTRON BODHI CLOUD
  crestron: {
    intro: {
      crestronBodhiCloudIdentifier: true,
      defaultBackgroundImagePath: '/images/home-page-intro-section-default-background-crestron.jpg',
      description: ' Guest centric control paired with comprehensive management',
      boldDescription: 'Match made in the cloud: ',
      logoImagePath: '/images/crestron-logo-dark.webp',
      smallBackgroundImagePath: '/images/home-page-intro-section-small-background-crestron.jpg',
      imageAlternateText: 'Crestron and Bodhi Logos',
      position: 'center',
      title: 'Bodhi',
      rowHeading: true,
      columnHeading: false,
      hasButton: false,
      color: '#000',
      increaseLogoSize: false,
    },
    description: {
      value:
        'Now a simple, property-wide software application centrally schedules, manages and controls all of your Crestron lighting, shading, and climate control systems with a single interface.',
    },
    gallery: {
      items: [
        {
          controlName: 'Meeting Center',
          description:
            'Bodhi makes it easy to make system adjustments during event planning, then schedule them to take place on the day of an event.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-01.jpg',
          imageAlternateText: 'Crestron 01',
          title: 'In the meeting center',
        },
        {
          controlName: 'Guest Room',
          description:
            'Bodhi constantly monitors crucial systems, then sends you alerts if something goes wrong. At the same time, the installation-free Bodhi Guest App gives your clients keyless room entry, the ability to order hotel services, and chat with management, all from their smart phones or other devices.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-02.jpg',
          imageAlternateText: 'Crestron 02',
          title: 'In guest rooms and residential units',
        },
        {
          controlName: 'Common areas',
          description:
            'Bodhi simplifies the scheduling and adjustment of audio and video systems, lighting scenes, and climate systems, without the need to bring in your integrator to make changes.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-03.jpg',
          imageAlternateText: 'Crestron 03',
          title: 'In common areas',
        },
        {
          controlName: 'Every Area',
          description:
            'Guests, homeowners, and your managers retain control of local systems, but you can control and schedule the functions you need to, and you can set constraints to keep your property safe and to avoid disturbances.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-04.jpg',
          imageAlternateText: 'Crestron 04',
          title: 'In every area',
        },
      ],
    },
    illustrations: {
      items: [
        {
          descriptionBoldedText: 'Bodhi’s built-in analytics and reporting ',
          descriptionRegularText:
            'gives you even more. Now you’ll be able to set up and track trouble tickets, track the life of system components, schedule preventative maintenance, track energy costs and savings, and review the use and value of specific systems.',
          imageAlternateText: 'Image for Reporting',
          imagePath: '/images/solution-page-illustrations-section-reports.png',
        },
        {
          descriptionBoldedText: 'You, your staff, clients and guests ',
          descriptionRegularText:
            'will access Bodhi from computers, smart phones, tablets and Crestron touch screens, so everyone has access from anywhere on the property, or away.',
          imageAlternateText: 'Image for Users',
          imagePath: '/images/solution-page-illustrations-section-users.png',
        },
      ],
    },
    examples: {
      items: [
        {
          description: [
            'Bodhi uses every innovation built into your Crestron lighting system, but adds functionality, simplicity and centralized management.',
            'You can set up schedules and scenes for a single unit, a group of units or an entire community. ',
          ],
          primaryTitle: 'Commercial Lighting',
          secondaryTitle: 'Scalable Control and Management for your Entire Property',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-01.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'With Bodhi, guests have full control over lighting levels and scenes within their own units but management gains centralized, intelligent management. ',
            'And, of course, you can continue to use any and all Crestron components, including their beautiful Horizon keypads.',
          ],
          primaryTitle: 'Local Lighting Control and Keypads',
          secondaryTitle: 'Simple and appealing',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-02.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-crestron-01.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Use Bodhi with your Crestron thermostats  to control virtually any kind of HVAC system.',
            'The combination even supports remote humidity and temperature sensors, including outdoor sensors.',
          ],
          primaryTitle: 'Crestron Thermostats',
          secondaryTitle: 'Greatly enhanced functionality and versatility',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-03.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-crestron-02.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Crestron automated custom window treatments add the ultimate in beauty, convenience and comfort to any rental or residential unit.',
            'With Bodhi and Crestron together, guests can set the mood with the touch of a button, while you have centralized control and comprehensive management.',
          ],
          primaryTitle: 'Shading',
          secondaryTitle: 'Adding beauty, convenience and comfort ',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-04.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi is fully compatible with Amazon Alexa, allowing guests to active lights, climate and entertainment systems by voice command while you monitor and manage your Amazon systems.',
          ],
          primaryTitle: 'Voice Control',
          secondaryTitle: 'Bodhi and Alexa',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Add Crestron occupancy sensors to your Bodhi-enhanced system to maximize energy savings without the fear of inconveniencing guests by falsely triggering ‘away’ settings.',
            'Their low-profile, unobtrusive design and superior motion sensing make them an ideal choice for your property.',
          ],
          primaryTitle: 'Occupancy Sensors',
          secondaryTitle: 'Automate at-home and away modes',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-crestron-04.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Crestron components enhance the richness and impact of the audio in guest rooms, common areas, meeting rooms and more.',
            'Bodhi simplifies control, operation and maintenance, enhancing the long-term return on your investment.',
          ],
          primaryTitle: 'Audio Control',
          secondaryTitle: 'Game changing technology',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-06.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-crestron-05.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Crestron and Bodhi unify the many technologies installed on your property, so they can work together intelligently, lowering your costs and boosting your efficiency and the efficiency of your staff.',
            'Together they provide the ultimate building control solution for any hotel, resort, or residential development.',
          ],
          primaryTitle: 'Crestron Control Systems',
          secondaryTitle: 'Property-wide Management',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-07.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-crestron-06.png',
          secondaryImageAlternateText: '',
        },
      ],
    },
  },
  // LUTRON
  lutron: {
    intro: {
      lutronBodhiIdentifier: true,
      defaultBackgroundImagePath: '/images/home-page-intro-section-default-background-lutron.jpg',
      boldDescription: 'Premium hospitality lighting: ',
      description: ' Cloud-based and budget friendly',
      logoImagePath: '/images/lutron-logo-dark.png',
      smallBackgroundImagePath: '/images/home-page-intro-section-small-background-lutron.jpg',
      title: 'Bodhi',
      imageAlternateText: 'Crestron and Lutron Logos',
      position: 'center',
      rowHeading: true,
      columnHeading: false,
      hasButton: false,
      color: '#000',
      increaseLogoSize: true,
    },
    description: {
      value:
        'Now a simple, property-wide software application centrally schedules, manages and controls all of your Lutron lighting, shading, and climate control systems with a single interface. It even works with basic standalone platforms including Caséta and HomeWorks.',
    },
    gallery: {
      items: [
        {
          controlName: 'Guest Room and Residence',
          description:
            'Bodhi gives you a browser-based dashboard, and your guests a browser-based app that can tie into your guestroom system. You will set ‘away’ lighting, climate and shading when guests are gone, ‘welcome’ levels when they return, and alert service staff on whether a room is occupied. Guests will use their smart phones to unlock the room door, control lights, shades and climate, and access hotel services and chat with staff.',
          imagePath: '/images/solution-page-gallery-section-carousel-lutron-01.jpg',
          imageAlternateText: 'Lutron 01',
          title: 'In guest rooms and residences',
        },
        {
          controlName: 'Restaurants',
          description:
            'Bodhi helps you create the perfect ambiance by centrally scheduling lights, temperature and shading, yet allows restaurant and bar managers to override those settings as conditions change or when scheduling special events.',
          imagePath: '/images/solution-page-gallery-section-carousel-lutron-02.jpg',
          imageAlternateText: 'Lutron 02',
          title: 'In restaurants and lounges',
        },
        {
          controlName: 'Common Areas',
          description:
            'In lobbies and common areas, Bodhi allows you to schedule lighting, shading and temperature by time and day of the week, or automatically adjust as sunrise and sunset vary with the seasons.',
          imagePath: '/images/solution-page-gallery-section-carousel-lutron-03.jpg',
          imageAlternateText: 'Lutron 03',
          title: 'In common areas',
        },
        {
          controlName: 'Meeting Center',
          description:
            'Use Bodhi to schedule lighting, shading and temperature settings in advance of each meeting, while allowing clients and staff to adjust them as needed via wall stations or your AV system controls.',
          imagePath: '/images/solution-page-gallery-section-carousel-lutron-04.jpg',
          imageAlternateText: 'Lutron 03',
          title: 'In the meeting center',
        },
      ],
    },
    illustrations: {
      items: [
        {
          descriptionBoldedText: 'Bodhi constantly monitors ',
          descriptionRegularText:
            'every Lutron component and sends alerts if something goes wrong.',
          imageAlternateText: 'Image for Monitoring',
          imagePath: '/images/solution-page-illustrations-section-monitor.png',
        },
        {
          descriptionBoldedText: 'If you use different Lutron systems ',
          descriptionRegularText:
            ' in different areas of your property, Bodhi brings them together under a single, simplified interface. You can tie in third-party components as well.',
          imageAlternateText: 'Image for Compatibility',
          imagePath: '/images/solution-page-illustrations-section-compatible.png',
        },
        {
          descriptionBoldedText: 'Bodhi’s built-in analytics and reporting ',
          descriptionRegularText:
            'helps you set up and track trouble tickets, track the life of system components, schedule preventative maintenance, track energy costs and savings, and review the use and value of specific systems.',
          imageAlternateText: 'Image for Reporting',
          imagePath: '/images/solution-page-illustrations-section-reports.png',
        },
        {
          descriptionBoldedText: 'You, your staff, clients and guests ',
          descriptionRegularText:
            'will access Bodhi from computers, smart phones, tablets and even your AV system’s touch screens, so everyone has access from anywhere on the property, or away.',
          imageAlternateText: 'Image for Users',
          imagePath: '/images/solution-page-illustrations-section-users.png',
        },
      ],
    },
    examples: {
      items: [
        {
          description: [
            'Bodhi and the Bodhi Bridge bring centralized control and management to Lutron’s most cost-effective smart lighting platform, adding floor plan-based navigation, dashboards for each room, automated ‘welcome’ and ‘away’ settings, and real-time alerts.',
          ],
          primaryTitle: 'Bodhi and Caséta',
          secondaryTitle: 'Cost-effective guestroom automation',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-01.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-01.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'With Bodhi, you can add Alexa-based voice control to any Lutron system. Guests will set lighting and temperature levels, operate shades and drapes with simple verbal commands.',
          ],
          primaryTitle: 'Bodhi and Alexa',
          secondaryTitle: 'Your guests will love voice control',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-02.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-02.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Use Bodhi with any version of myRoom to add cloud-based management, a consistent interface with other Lutron systems, and the ability to tie in third-party devices.',
          ],
          primaryTitle: 'Bodhi and myRoom',
          secondaryTitle: 'Cloud-based sophistication',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-03.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-03.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Use HomeWorks for a more elegant interface in residential units and luxury suites; add Bodhi for centralized management, automated ‘welcome’ and ‘away’ settings, and real-time alerts.',
          ],
          primaryTitle: 'Bodhi and HomeWorks',
          secondaryTitle: 'Refined lighting, daylight and temperature control',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-04.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-04.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi adds centralized management and troubleshooting to Lutron’s whole-home solution, making the combination a great choice for residential developments and resorts.',
          ],
          primaryTitle: 'Bodhi and RadioRA 2',
          secondaryTitle: 'Flexible and scalable',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-05.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Lutron’s commercial lighting control platform gets better with Bodhi. Now you and your managers will be able to set up and adjust schedules, scenes, reporting and alerts, without the need for help from your Lutron contractor.',
          ],
          primaryTitle: 'Bodhi and Athena/Quantum',
          secondaryTitle: 'An anyone-can-do-it interface',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-06.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-06.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi extends the power of lower-cost Lutron platforms to include centralized control and management, while at the same time providing a single, simple interface for the various Lutron systems and third-party devices you may use on your property',
            'Together Lutron and Bodhi create the ultimate lighting, shading and climate control solution for any hotel, resort, or residential community.',
          ],
          primaryTitle: 'Bodhi and Lutron ',
          secondaryTitle: 'A single, simple interface',
          primaryImagePath: '/images/solution-page-examples-section-primary-lutron-07.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-lutron-07.png',
          secondaryImageAlternateText: '',
        },
      ],
    },
  },
  // BODHI SENSORS
  bodhi: {
    intro: {
      bodhiSensorsIdentifier: true,
      partnerPage: true,
      defaultBackgroundImagePath:
        '/images/home-page-intro-section-default-background-bodhi-sensors.jpg',
      boldDescription: 'Building technology at a fraction of the cost: ',
      description: ' The control & management needed in any property',
      logoImagePath:
        '/images/solution-page-intro-section-logo-bodhi-wireless-sensors-and-devices.png',
      smallBackgroundImagePath:
        '/images/home-page-intro-section-default-background-bodhi-sensors-small.jpg',
      imageAlternateText: 'Z Wave Background',
      position: 'center',
      rowHeading: false,
      columnHeading: false,
      hasButton: false,
      color: '#fff',
      increaseLogoSize: false,
      title: (
        <Fragment>
          Bodhi Wireless
          <br />
          Sensors and Devices
        </Fragment>
      ),
    },
    description: {
      value:
        'Bodhi wireless devices centrally control climate and lights, detect occupancy, and protect against floods, leaks, humidity, and security issues.',
    },
    gallery: {
      items: [
        {
          controlName: 'Guest Room and Residence',
          description:
            'Add flood, leak, open-door and open-window protection to any space, while the browser-based Bodhi Guest App provides keyless room entry, room controls and access to services from any smart phone.',
          imagePath: '/images/solution-page-gallery-section-carousel-z-wave-01.jpg',
          imageAlternateText: 'Bodhi 01',
          title: 'In guest rooms and residences',
        },
        {
          controlName: 'Common Areas',
          description:
            'Bodhi adds security and functionality throughout your property, with low-cost wireless devices scheduled and controlled with an easy-to-use browser-based interface.',
          imagePath: '/images/solution-page-gallery-section-carousel-z-wave-02.jpg',
          imageAlternateText: 'Bodhi 02',
          title: 'In common areas and restaurants',
        },
        {
          controlName: 'Meeting Center',
          description:
            'Bodhi adds cloud-based scheduling and control of lights, temperature, and electrical appliances such as warmers and coffee urns. Add it to your Crestron system for greater simplicity and flexibility!',
          imagePath: '/images/solution-page-gallery-section-carousel-z-wave-03.jpg',
          imageAlternateText: 'Bodhi 03',
          title: 'In the meeting center ',
        },
      ],
    },
    illustrations: {
      items: [
        {
          descriptionBoldedText: 'Bodhi monitors ',
          descriptionRegularText: 'every component and alerts you if something goes wrong.',
          imageAlternateText: 'Image for Monitoring',
          imagePath: '/images/solution-page-illustrations-section-monitor.png',
        },
        {
          descriptionBoldedText: 'Bodhi’s built-in analytics and reporting ',
          descriptionRegularText:
            'sets up and tracks trouble tickets, tracks the life of system components, schedules preventative maintenance, tracks energy costs and savings, and reviews the use and value of specific systems.',
          imageAlternateText: 'Image for Reporting',
          imagePath: '/images/solution-page-illustrations-section-reports.png',
        },
        {
          descriptionBoldedText: 'You, your staff, clients and guests ',
          descriptionRegularText:
            'will access Bodhi from computers, smart phones, tablets and even your AV system’s touch screens, so everyone has access from anywhere on the property, or away.',
          imageAlternateText: 'Image for Users',
          imagePath: '/images/solution-page-illustrations-section-users.png',
        },
      ],
    },
    examples: {
      items: [
        {
          description: [
            'A simple device that greatly enhances your ability to manage the many technologies on your property.',
            'The Bridge communicates with up to 32 Bodhi wireless sensors and other devices, plus two AC power or water meters, specialized sensors or instruments using standard general purpose inputs. It links them to Bodhi via your Wi-Fi network or an optional LTE cellular card.',
          ],
          primaryTitle: 'Bodhi Bridge',
          secondaryTitle: 'Wireless hub for Bodhi',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-08.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-08.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'An elegant, one-piece temperature and humidity controller with integrated occupancy sensor able to control nearly any kind of HVAC system.',
            'It communicates with Bodhi via either your  Wi-Fi network or an optional LTE cellular connection, signals the presence of guests and is controlled by Bodhi in return.',
            'With studies by Energy Star showing that an energy management system can save hotels 35% - 45% of their total guestroom energy costs by turning down the climate systems in unrented rooms, it only makes sense to install such a system.',
            'Yet the Bodhi Thermostat goes beyond those basic savings by allowing Bodhi to trigger temperature, lighting, and other setbacks even when rooms are rented, but guests are away.',
          ],
          primaryTitle: 'Bodhi Thermostat',
          secondaryTitle: 'Smart thermostat with occupancy sensor',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-09.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-09.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Like the Thermostat, the Bodhi Thermostat Bridge can save at least 35% - 45% of your guestroom energy costs using its built-in occupancy sensor and encrypted Wi-Fi or LTE cellular communications with Bodhi Cloud.',
            'At the same time, the Thermostat Bridge can connect up to 32 Bodhi wireless devices, plus standard water meters, AC power meters, specialized instruments and sensors via a general purpose wired input.',
            'In this way, this single, low-cost device can be the hardware hub of any Bodhi-controlled room, providing everything you need to monitor, manage and control all of the technology in a guestroom or residence.',
          ],
          primaryTitle: 'Bodhi Thermostat Bridge',
          secondaryTitle: 'Smart thermostat with integrated occupancy sensor and Bodhi Bridge',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-10.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-10.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'The browser-based Bodhi Guest App gives guests, homeowners and other clients control over room entry and appropriate devices from their smart phones, tablets and computers.',
            'In doing so, it empowers your guests to access the key technologies and services they need with the device they already love (their smart phone) – while removing unnecessary delays and annoyances.',
            'Simply give the client a link to the Guest App via text or email and it automatically populates with his or her name and room or unit number.',
            'It provides keyless room entry from most electronic lock systems, acts as a remote control for lights, climate, TVs and entertainment, requests the valet, housekeeping or reservations, provides chat with the front desk or concierge, can include web-based information, maps and announcements, and may be customized with your branding or added to your branded app.',
          ],
          primaryTitle: 'Bodhi Guest App',
          secondaryTitle: 'Keyless entry, control and amenities from any device, any browser',
          primaryImagePath: '/images/solution-page-examples-section-primary-guest-app.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },

        {
          description: [
            'Bodhi is fully compatible with Amazon Alexa, allowing guests to active lights, climate and entertainment systems by voice command while you monitor and manage your Amazon systems.',
          ],
          primaryTitle: 'Bodhi and Alexa',
          secondaryTitle: 'Voice Control',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Add a new level of protection to the property by alerting security when a guest leaves a door or window open. You can even trigger events such as shutting down the AC if they stay open past the timespan you specify.',
          ],
          primaryTitle: 'Bodhi Door and Window Sensors',
          secondaryTitle: 'Alerts plus triggered events when doors or windows are left open',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-01.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-01.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Add low-cost motion sensors to confirm whether a room is occupied or empty and trigger ‘away’ settings for lights, temperature, AV and security. ',
          ],
          primaryTitle: 'Bodhi Motion Sensors',
          secondaryTitle: 'Monitor whether a room is occupied',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-02.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-02.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Detect leaks under sinks, behind refrigerators, or in low-traffic utility areas, then alert staff to make needed repairs. Can also alert you to low temperatures due to HVAC system failure. ',
          ],
          primaryTitle: 'Bodhi Flood / Water Sensors  ',
          secondaryTitle: 'Catch leaks before they cause damage',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-03.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-03.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Track humidity and temperature independently of thermostats, alerting you to possible problems and triggering fans or other devices to prevent damage.',
          ],
          primaryTitle: 'Bodhi Temperature / Humidity Sensors',
          secondaryTitle: 'Monitor and mitigate humidity issues',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-04.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-04.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Mold and mildew can be a major concern in warmer climates, where even a tiny water leak can promote dangerous mold growth.',
            'Take control by linking Bodhi to an occupancy sensor and water meter in each residential unit or guest room. Should the meter show a flow when the unit is empty, Bodhi will send an alert to maintenance to check for a leak.',
            'Need to add more water meters to make flow detection practical? This simple device is inexpensive enough to install in each unit. Wireless communications make installation a snap.',
          ],
          primaryTitle: 'Bodhi Wireless Water Meter',
          secondaryTitle: 'Wireless meter makes leak detection simple',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-11.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-11.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Control any plug-in device with your Bodhi system. Shut down lamps and appliances when a room is unoccupied or control warmers and urns in your restaurants and meeting center.',
          ],
          primaryTitle: 'Bodhi Controllable Outlets ',
          secondaryTitle: 'Monitor and control plug-in devices',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-05.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Add lower-cost switches and dimmers to a Lutron or Crestron lighting system, or build a Bodhi-only wireless system to automate the lighting in your guestrooms. ',
          ],
          primaryTitle: 'Bodhi In-Wall Switches and Dimmers   ',
          secondaryTitle: 'Cost-effective guestroom automation',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-06.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-06.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi extends and simplifies the control and management of the many technologies on your property.',
            'With the Bodhi Bridge, you’ll add the power of Bodhi wireless devices to your Crestron and Lutron systems, and be able to build simple, very low-cost systems with Bodhi devices alone.',
            'Bodhi is the ultimate control and management solution for any hotel, resort, or residential community.',
          ],
          primaryTitle: 'Bodhi Wireless Sensors and Devices',
          secondaryTitle: 'Dramatically expand your options',
          primaryImagePath: '/images/solution-page-examples-section-primary-bodhi-07.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '/images/solution-page-examples-section-secondary-bodhi-07.png',
          secondaryImageAlternateText: '',
        },
      ],
    },
  },
  // CRESTRON HOME
  crestronHome: {
    intro: {
      crestronHomeBodhiIdentifier: true,
      defaultBackgroundImagePath:
        '/images/home-page-intro-section-default-background-crestron-home.jpg',
      boldDescription: 'Crestron at its most intuitive: ',
      description: ' Simplified management of guest rooms and residences',
      logoImagePath: '/images/crestron-home-os.png',
      smallBackgroundImagePath:
        '/images/home-page-intro-section-default-background-crestron-home-small.jpg',
      title: 'Bodhi',
      imageAlternateText: 'Z Wave Background',
      position: 'left',
      rowHeading: false,
      columnHeading: true,
      hasButton: false,
      color: '#000',
      increaseLogoSize: false,
    },
    description: {
      value:
        'Home is Crestron’s most affordable, easiest-to-deploy control solution. Bodhi works with Crestron Home OS to link it to your property management system, expand its capabilities and simplify its use.',
    },
    gallery: {
      items: [
        {
          controlName: 'Guest Rooms & Suites',
          description:
            'Bodhi will help you create the perfect ambience for your guests. You will set ‘welcome’ lighting, music, climate and shading when they enter the unit, and energy-saving ‘away’ levels when they leave, without compromising their local control. They will use their smart phones to unlock the room door, control lights, shades and climate, and access hotel services and chat with staff.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-home-01.jpg',
          imageAlternateText: 'Bodhi 01',
          title: 'In guest rooms and suites',
        },
        {
          controlName: 'Condos & Resorts',
          description:
            'Bodhi adds comprehensive, property-wide management to Crestron Home, enhancing your guests’ comfort, security and satisfaction while saving staff and energy costs.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-home-02.jpg',
          imageAlternateText: 'Bodhi 02',
          title: 'In condos and resort communities',
        },
        {
          controlName: 'Homes',
          description:
            'Bodhi brings true simplicity to Crestron Home. Now homeowners can modify or set up lighting, climate and entertainment presets, scenes and schedules, all from a browser-based interface they access from their computers, phones, or tablets.',
          imagePath: '/images/solution-page-gallery-section-carousel-crestron-home-03.jpg',
          imageAlternateText: 'Bodhi 03',
          title: 'In individual homes',
        },
      ],
    },
    illustrations: {
      items: [
        {
          descriptionBoldedText: 'Bodhi monitors ',
          descriptionRegularText:
            'Crestron Home and the components it connects to and alerts you if something goes wrong.',
          imageAlternateText: 'Image for Monitoring',
          imagePath: '/images/solution-page-illustrations-section-monitor.png',
        },
        {
          descriptionBoldedText: 'Bodhi’s built-in analytics and reporting ',
          descriptionRegularText:
            'generates trouble tickets, tracks the life of system components, schedules preventative maintenance, tracks energy costs and savings, and reviews the use and value of specific systems.',
          imageAlternateText: 'Image for Reporting',
          imagePath: '/images/solution-page-illustrations-section-reports.png',
        },
        {
          descriptionBoldedText: 'You, your staff, clients and guests ',
          descriptionRegularText:
            'will access Bodhi from computers, smart phones, tablets and Crestron touch screens, so everyone has access from anywhere on the property, or away.',
          imageAlternateText: 'Image for Users',
          imagePath: '/images/solution-page-illustrations-section-users.png',
        },
      ],
    },
    examples: {
      items: [
        {
          description: [
            'Bodhi ties Crestron Home to the leading hospitality management platforms, including ALICE, Opera and Amadeus HotSOS, to automate almost any kind of electronic system  based on occupancy.',
          ],
          primaryTitle: 'Hotel Operating Systems',
          secondaryTitle: 'Bodhi and your Property Management System',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-01.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi controls all of Crestron’s lighting, shading and drapery systems, adding simplicity, functionality and centralized management.',
            'Use it to set up schedules and scenes for a single unit, a group of units or an entire community.',
          ],
          primaryTitle: 'Lighting and shading',
          secondaryTitle: 'Bodhi and Crestron lighting systems',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-02.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Use Bodhi and Crestron Home to control and automate virtually any heating and cooling system, setting upper and lower limits, and scheduling temperature and humidity by time of day, season and occupancy.',
          ],
          primaryTitle: 'Heating, cooling, and humidity',
          secondaryTitle: 'Climate control',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-03.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Guests will control their entertainment systems with Crestron Home. You’ll use Bodhi to trigger welcome scenes, and monitor the health and usage of your audio and video gear.',
          ],
          primaryTitle: 'Entertainment systems',
          secondaryTitle: 'Set the mood with audio and video',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-04.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi is fully compatible with Amazon Alexa, allowing guests to active lights, climate and entertainment systems by voice command while you monitor and manage your Amazon systems.',
          ],
          primaryTitle: 'Voice Control',
          secondaryTitle: 'Bodhi and Alexa',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Add a range of third-party devices and systems that would not normally be compatible with Crestron Home, greatly enhancing its functionality and value.',
            'For example, low-cost sensors can alert security should a door or window be left open for an extended time, occupancy sensors can confirm whether guests are present, and moisture, humidity and electrical sensors can alert maintenance of leaks and unusual current draws.',
          ],
          primaryTitle: 'Third party components and systems',
          secondaryTitle: 'Extended device control',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-06.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath:
            '/images/solution-page-examples-section-secondary-crestron-home-06.png',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Bodhi extends the power of Crestron Home to include centralized control and management, while at the same time providing a single, simple interface for the various Crestron systems you may use on your property.',
            'Together Crestron and Bodhi create the ultimate lighting, shading, entertainment and climate control solution for any hotel, resort, or residential community.',
          ],
          primaryTitle: 'Crestron and Bodhi',
          secondaryTitle: 'A single, simple interface',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-07.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
      ],
    },
  },
  // RAKO
  rako: {
    intro: {
      rakoBodhiIdentifier: true,
      defaultBackgroundImagePath: '/images/home-page-intro-section-default-background-rako.png',
      boldDescription: '',
      description: 'Simple, flexible and intuitive lighting control',
      logoImagePath: '/images/rako-logo-dark.png',
      smallBackgroundImagePath: '/images/home-page-intro-section-small-background-rako.png',
      title: 'Bodhi',
      imageAlternateText: 'Bodhi and Rako Logos',
      position: 'center',
      rowHeading: true,
      columnHeading: false,
      hasButton: false,
      color: '#000',
      increaseLogoSize: true,
    },
    description: {
      value: `Use Bodhi's simple, single interface to control a Rako lighting control system, both wired and wireless via the Rako Hub. Providing and interface to manage all of your Rako lighting and shading for hospitality, commercial, and residential environments.`,
    },
    gallery: {
      items: [
        {
          controlName: 'Hotel Rooms & Suites',
          description: `With Bodhi’s browser-based dashboard interface, hotels can automatically trigger welcome scenes via the Rako Hub; including lighting scenes, temperature and background music for their guests, adding sophistication and luxury to the guest experience. Guests can then use their smartphones to access lighting control alongside other hotel services. Feedback for front-of-house also enables staff to set an ‘away’ setting when guests leave the hotel.`,
          imagePath: '/images/solution-page-gallery-section-carousel-rako-01.jpg',
          imageAlternateText: 'Bodhi 01',
          title: 'In Hotel Rooms & Suites',
        },
        {
          controlName: 'Bars & Restaurants',
          description: `Using Bodhi in conjunction with Rako’s lighting control system, the perfect ambience can be achieved in restaurants, bars and hotels for guests to enjoy. Lighting scenes can be subtly changed, enhancing the customer experience with smooth fading, reducing the impact and intrusion of cumbersome switching, whilst dining and public areas benefit from a more relaxing feel with scenes becoming more intimate as the evening progresses. Scene changes can be easily selected by staff or automatically from repeatable, timed events.`,
          imagePath: '/images/solution-page-gallery-section-carousel-rako-02.jpg',
          imageAlternateText: 'Bodhi 02',
          title: 'In Bars & Restaurants',
        },
        {
          controlName: 'Commercial and Meeting Spaces',
          description: `With Bodhi and Rako, office, meeting and conference facilities take on a more professional image with simple to select scenes for presentations and meetings. Lighting and shading can be adjusted to suit the time of day or the required task.`,
          imagePath: '/images/solution-page-gallery-section-carousel-rako-03.jpg',
          imageAlternateText: 'Bodhi 03',
          title: 'Commercial & Meeting Spaces',
        },
        {
          controlName: 'Residential',
          description: `Rako’s wired and wireless lighting control system is flexible and scalable, from single room control to extensive apartment developments. Select a welcome scene for your guests as well as allowing them to have control over their own environment.`,
          imagePath: '/images/solution-page-gallery-section-carousel-rako-04.jpg',
          imageAlternateText: 'Bodhi 04',
          title: 'Residential',
        },
      ],
    },
    illustrations: {
      items: [],
    },
    examples: {
      items: [
        {
          description: [
            'Voice control provides guests with simple, straightforward control. Use Bodhi with Alexa to control any Rako control system, enabling guests to change lighting scenes, shading, music and temperature.',
          ],
          primaryTitle: 'Voice Control',
          secondaryTitle: '',
          primaryImagePath: '/images/solution-page-examples-section-primary-crestron-home-05.jpg',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            `Bodhi can monitor Rako’s energy consumption, enabling users to monitor expenditure and identify areas for optimisation.`,
          ],
          primaryTitle: 'Energy Management',
          secondaryTitle: '',
          primaryImagePath: '/images/solution-page-examples-section-primary-rako-01.png',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            `Rako’s award winning keypads come in a range of styles, to suit all interior design and aesthetic requirements. Modular and Classic keypads are available in multiple button configurations as standard whilst EOS keypads offer a sleek, all metal interface. Custom engraved buttons are available across all keypad ranges.`,
          ],
          primaryTitle: 'Keypad Range',
          secondaryTitle: '',
          primaryImagePath: '/images/solution-page-examples-section-primary-rako-02.png',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
      ],
    },
  },

  // SILENT GLISS
  silentGliss: {
    intro: {
      silentGlissBodhiIdentifier: true,
      // defaultBackgroundImagePath: '/images/home-page-intro-section-default-background-rako.png',
      defaultBackgroundImagePath: '/images/silent-gliss-main-slide-content.webp',
      boldDescription: '',
      description: 'Smart Shading for your comfort and security.',
      logoImagePath: '/images/silent-gliss-logo.png',
      smallBackgroundImagePath: '/images/home-page-intro-section-small-background-rako.png',
      title: 'Bodhi',
      imageAlternateText: 'Bodhi and Silent Glass Logos',
      position: 'center',
      rowHeading: true,
      columnHeading: false,
      hasButton: false,
      color: '#000',
      increaseLogoSize: true,
    },
    description: {
      value: `Use Bodhi’s simple, single interface to control your Silent Gliss shading systems. Whether curtains, blinds or a combination of both, Silent Gliss offers a wide range of seamlessly integrated shading systems for hospitality, commercial, and residential interiors.`,
    },
    gallery: {
      items: [
        // pre_15060
        {
          silentGlissBodhiIdentifier: true,
          controlName: 'Hotel Rooms & Suites',
          description: `With Bodhi’s browser-based dashboard interface, hotels can automatically trigger welcome scenes that include the position of the Silent Gliss curtains and blinds, adding sophistication and luxury to the guest experience. Guests can then use their smartphones to access the shading systems to adjust at their convenience. Feedback for front-of-house also enables staff to set an ‘away’ setting when guests leave the hotel.`,
          imagePath: '/images/silent-gliss-hotel-rooms-and-suites.webp',
          // imagePath: '/images/silent-gliss-hotel-rooms-and-suites.png',
          imageAlternateText: 'Bodhi 01',
          title: 'In Hotel Rooms & Suites',
        },
        {
          silentGlissBodhiIdentifier: true,
          controlName: 'Bars & Restaurants',
          description: `Using Bodhi in conjunction with Silent Gliss shading systems, the perfect ambience can be achieved in restaurants, bars, and hotels. Curtain and blind positions can be subtly changed, enhancing the customer experience with smooth and silent adjustments. By removing cumbersome switching, dining and public areas benefit from a more relaxing feel with scenes becoming more intimate as the evening progresses. Scene changes can be easily selected by staff or automatically from repeatable, timed events.`,
          imagePath: '/images/silent-gliss-bars-and-restaurants.webp',
          imageAlternateText: 'Bodhi 02',
          title: 'In Bars & Restaurants',
        },
        {
          silentGlissBodhiIdentifier: true,
          controlName: 'Commercial and Meeting Spaces',
          description: `With Bodhi and Silent Gliss, office, meeting, and conference facilities take on a more professional image with simple-to-select scenes for presentations and meetings. The shading can be adjusted alongside the lighting to suit the time of day or the required task.`,
          imagePath: '/images/silent-gliss-commercial-and-meeting-spaces.webp',
          imageAlternateText: 'Bodhi 03',
          title: 'Commercial & Meeting Spaces',
        },
        {
          silentGlissBodhiIdentifier: true,
          controlName: 'Residential',
          description: `Silent Gliss offers the widest range of Smart shading systems, whether for large or small windows, single room control or extensive apartment developments. They provide solutions that guarantee seamless integration, comfort, and security. Select a welcome scene for your guests as well as allowing them to have control over their own environment.`,
          imagePath: '/images/silent-gliss-residential.webp',
          imageAlternateText: 'Bodhi 04',
          title: 'Residential',
        },
      ],
    },
    illustrations: {
      items: [],
    },
    examples: {
      items: [
        {
          description: [
            `Silent Gliss offers made-to-measure curtains and blinds with fabrics from The Collection – a range of proven, high-quality flame-retardant fabrics, removing the need to work with additional suppliers.`,
          ],
          primaryTitle: 'Made-To-Measure Shading',
          secondaryTitle: '',
          primaryImagePath: '/images/silent-gliss-made-to-measure-shading.webp',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            'Voice control provides guests with simple, straightforward control. Use Bodhi with Alexa to control Silent Gliss curtains and blinds alongside the lighting to create the perfect ambience.',
          ],
          primaryTitle: 'Voice Control',
          secondaryTitle: '',
          primaryImagePath: '/images/silent-gliss-voice-control.webp',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
        {
          description: [
            `Bodhi enhances energy management with Silent Gliss by monitoring real-time consumption, providing optimization insights, and automating settings such as lowering the shades to reduce energy costs. It enables proactive control with alerts, scheduling, and energy usage comparisons across spaces.`,
          ],
          primaryTitle: 'Energy Management',
          secondaryTitle: '',
          primaryImagePath: '/images/silent-gliss-energy-management.webp',
          primaryImageAlternateText: '',
          secondaryImagePath: '',
          secondaryImageAlternateText: '',
        },
      ],
    },
  },
};

// PROJECT PAGE CONTENT
export const project = {
  // ART OVATION HOTEL
  artOvationHotel: {
    headImagePath: '/images/project-page-head-section-art-ovation-hotel.jpg',
    title: 'the Art Ovation Hotel, Sarasota',
    subtitle: 'Simplified scheduling and control of music, video, lighting and climate',
    bodyItems: [
      {
        type: 'paragraph',
        value:
          'One of the great things about Bodhi is the ease in which property managers and staff can adjust the scheduling of their music, video, lighting, and climate control systems themselves, without the need to bring back their technology integrator, as is true of other control systems. “At the Art Ovation, they use that ability every single day to update lighting scenes, audio levels and other presets to fit each event and the people they expect to attend it,” says Will Gilbert, partner at Software Development LLC, the company that created Bodhi.',
      },
      {
        type: 'paragraph',
        value:
          '“While they can still make adjustments on the fly, it’s far easier to set up the various systems in advance,” he explains.',
      },
      {
        type: 'image',
        value: '/images/project-page-body-section-side-art-ovation-hotel.jpg',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi’s browser-based interface makes it simple for the catering manager to choose settings and scenes for the entire property, but then allow individual managers –say of the restaurant, lobby bar and rooftop bar– to tweak the settings based on their own special experience.',
      },
      {
        type: 'paragraph',
        value:
          'Each can use his or her own smart phone or the computers, iPads and installed Crestron panels the property provides.',
      },
      {
        type: 'heading',
        value: 'A uniquely successful property',
      },
      {
        type: 'paragraph',
        value:
          'With its focus on celebrating the visual and performing arts,  the Art Ovation offers a unique and attractive experience for guests.',
      },
      {
        type: 'paragraph',
        value:
          'Its lobby and common areas feature paintings and sculptures on loan from the nearby Ringling College of Art and Design, and the property hosts regular art exhibitions and workshops. Friday-night jazz performances highlight local bands and vocalists.',
      },
      {
        type: 'paragraph',
        value:
          'A beautiful rooftop bar and pool deck offers spectacular views of the downtown and harbor, plus a venue for Saturday-night DJs who tie into its exceptional house sound and lighting systems.',
      },
      {
        type: 'paragraph',
        value:
          'The 6,100-square foot ballroom hosts lectures, banquets, weddings, and a wide variety of client meetings and events, each requiring its own unique setup.',
      },
      {
        type: 'paragraph',
        value:
          'A traditional control system might include a large number of sound, video and lighting presets, intended by the integrator to serve all these kinds of functions, but it would be impossible in a venue like this to anticipate all of the needed variations.',
      },
      {
        type: 'paragraph',
        value:
          'So when Gilbert approached property management and explained how Bodhi could enhance and simplify their events, they responded enthusiastically. The cloud-based Bodhi platform has been in constant use at the property since its opening in 2018.',
      },
      {
        type: 'carousel',
        value: [
          {
            title: 'Art Ovation Lobby',
            imagePath: '/images/project-page-body-section-carousel-art-ovation-01.jpg',
          },
          {
            title: 'Art Ovation Lobby Bar',
            imagePath: '/images/project-page-body-section-carousel-art-ovation-02.jpg',
          },
          {
            title: 'Art Ovation Rooftop',
            imagePath: '/images/project-page-body-section-carousel-art-ovation-03.jpg',
          },
        ],
      },
      {
        type: 'heading',
        value: 'Bodhi vs traditional control systems',
      },
      {
        type: 'paragraph',
        value:
          'Like many hotels and resorts, the Art Ovation has a Crestron control system that operates its common-area systems, including audio, video, lighting, digital signage, and climate. Managers have the choice of six channels of background music, which they can route into nine audio zones, covering the lobby, lobby bar, restaurant, ballroom, ballroom lobby, the fitness center, gift shop, elevators, and rooftop.',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi works with the Crestron system to add simple, browser-based automation, scheduling, and management of all the functions that it controls.',
      },
      {
        type: 'paragraph',
        value:
          'In most properties, if the restaurant managers want to turn lights down and play a specific source on their background music system at the same time every evening, the integrator would program the Crestron system to do that for them. But if they decide they want to change those scheduled settings, the integrator has to come back to reprogram the system.',
      },
      {
        type: 'paragraph',
        value:
          'For that reason, most hotels rarely change or tweak these presets, instead making any necessary changes manually, as needed, with their Crestron control panels. The next day, the system goes back to its default, and if they decide they like the changes they made, they’ll need to make them over and over again until the integrator can return.',
      },
      {
        type: 'paragraph',
        value:
          'Those kinds of service calls are no longer necessary at the Art Ovation. Bodhi makes it so simple to change any setting, and then save it to the default or save it as a new preset, that management can tweak the lights, audio and video whenever they wish.',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi even includes a simple calendar function, so managers can set, for example, the audio and lighting levels in advance for a trade show on Tuesday, a banquet on Wednesday, a jazz performance on Friday and a wedding on Saturday. And on the night of each event, should the event manager want to tweak those levels again, they can still do it from a wall-mounted Crestron panel or one of the catering department’s iPads.',
      },
      {
        type: 'Unique and powerful technology',
        value:
          'One unusual use of Bodhi at the Art Ovation is a display of digital artwork on six 65” Sunbright outdoor monitors in the ceiling of the porte-cochère at the entrance to the hotel. The images are sent to the displays by a Samsung digital signage system inside the hotel, which in turn is controlled and scheduled by Bodhi.',
      },
      {
        type: 'paragraph',
        value:
          'In the conference center, Bodhi controls a room-combining sound system and allows managers to schedule its function in advance. They can use Bodhi to route audio from any section of the ballroom, a nearby boardroom or the prefunction area to any or all others.',
      },
      {
        type: 'paragraph',
        value:
          'On the rooftop, Bodhi manages and controls an LED lighting system with color-changing bulbs that give the bar and pool deck a unique evening ambiance, as well as a 15-speaker performance sound system that DJs use on Saturday nights.',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi constantly checks on the health of all these systems, sends alerts and generates trouble tickets if a component fails or starts to move out of spec. In this way, management is able to deal with potential problems before they can turn into system failures.',
      },
      {
        type: 'paragraph',
        value:
          'The Art Ovation has been extremely popular, with its rooftop now considered a “must see” for visitors to Sarasota, especially at sunset. Visitor reviews average 4.5 stars on Orbitz and TripAdvisor and 4.8 on Marriott.com. The owners and managers say they appreciate the contributions Bodhi has made to achieving this success.',
      },
      {
        type: 'paragraph',
        value: (
          <Fragment>
            They love it so much that they have deployed it in a newer property, {''}
            <a
              className="project-head-main-paragraph-link"
              href="/projects/the-daytona"
              target="_blank"
            >
              The Daytona in Daytona Beach.
            </a>
          </Fragment>
        ),
      },
    ],
  },
  // THE DAYTONA HOTEL
  theDaytona: {
    headImagePath: '/images/project-page-head-section-the-daytona.jpg',
    title: 'the Daytona, Daytona Beach',
    subtitle: 'Revolutionary technology control helps catering managers simplify events',
    bodyItems: [
      {
        type: 'paragraph',
        value:
          'It’s tough enough being a catering manager without having to deal with technology issues.  Last minute client requests, changes and questions can make any event enough of challenge without stopping everything to adjust the audio or lighting, or to deal with a fault in the system.',
      },
      {
        type: 'paragraph',
        value:
          'But at the Daytona, a Marriott Autograph Collection Hotel, technology is not an issue. With the help of the Bodhi building operation platform, virtually all of the adjustments and tweaks can be made in advance—and it constantly checks the health of every component to avoid any problems.',
      },
      {
        type: 'paragraph',
        value:
          '“We designed Bodhi with hotel managers in mind,” explains Will Gilbert, partner at Software Development LLC.',
      },
      {
        type: 'paragraph',
        value:
          '“First, it makes it easy for managers and authorized staff to create and save their own lighting scenes, audio levels and other presets, then update or tweak them for a particular event. While our clients can always make adjustments on the fly, we know it’s far easier to fine-tune the settings during the planning stage.',
      },
      {
        type: 'paragraph',
        value:
          '“Just as important, it flags potential problems before they can disrupt a meeting or event. It’s a comprehensive system that simplifies technology management and makes every day go more smoothly.”',
      },
      {
        type: 'image',
        value: '/images/project-page-body-section-side-the-daytona.jpg',
      },
      {
        type: 'heading',
        value: 'A motor-themed luxury hotel',
      },
      {
        type: 'paragraph',
        value:
          'With a terrace and guest rooms offering views of the Daytona International Speedway, the Daytona offers an extraordinary experience for anyone who likes cars or motor sports.',
      },
      {
        type: 'paragraph',
        value:
          'Its racing-themed amenities include a second floor lobby with vintage race cars on display, and racing exhibits in its Sir Malcolm restaurant, Blue Flame bar, and conference center.',
      },
      {
        type: 'paragraph',
        value:
          'The property features 12,000 square feet of indoor and outdoor event spaces, plus a huge outdoor LED display near the front entrance, all of which are managed by Bodhi.',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi uses a simple, browser-based interface to allow the catering or IT manager to choose settings and scenes for the entire property, but also to delegate specific setups to the those most involved in particular phases of an event.  Managers and staff with the appropriate logins can access Bodhi with their office computers, iPads or their own smartphones.',
      },
      {
        type: 'paragraph',
        value:
          'If, for example, a client is holding a banquet and presentation in the property’s Echelon meeting rooms, a manager can set up the sound and video systems in advance, then schedule everything to be turned on and ready when the client arrives.',
      },
      {
        type: 'paragraph',
        value:
          'That scheduled program can include routing audio and video to the combined Echelon rooms, the prefunction area and the terrace outside, plus lighting levels, volume levels, and even a welcome message for the outdoor LED display.',
      },
      {
        type: 'paragraph',
        value:
          'Because Bodhi is constantly monitoring all of these systems, managers and guests can be confident that they will work perfectly during an event. Electronic components, for example, will start to draw more power before they malfunction, and Bodhi will catch that and send a notification to the IT department. That way, they can repair or replace the component before it can fail during an event.',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi also monitors and controls the background music, lighting, digital signage, and climate systems in the lobby, elevators, restaurant and bar, and allows managers to set up and adjust all of them to suit the day, the time and the events taking place.',
      },
      {
        type: 'carousel',
        value: [
          {
            imagePath: '/images/project-page-body-section-carousel-the-daytona-01.jpg',
            title: 'Sir Malcom Restaurant',
          },
          {
            imagePath: '/images/project-page-body-section-carousel-the-daytona-02.jpg',
            title: 'Blue Flame Bar',
          },
          {
            imagePath: '/images/project-page-body-section-carousel-the-daytona-03.jpg',
            title: 'The Daytona Lobby',
          },
        ],
      },
      {
        type: 'heading',
        value: 'Simplified access, control, and scheduling',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi is a cloud-based management application accessed by any device with a web browser, with all communications protected by 256-bit encryption. Because it uses role-based authentication, the hotel director or IT manager can retain overall control but easily delegate specific systems, say the lighting, video and music in the Blue Flame bar, to the appropriate managers and staff.',
      },
      {
        type: 'paragraph',
        value:
          'The first thing users see on opening Bodhi is a Dashboard with the various systems under their control. A high-level view would start with buttons for each floor of the hotel plus combined scenes for the ballroom, prefunction areas and outdoors. (Lower-level managers see only the systems under their specific control.)',
      },
      {
        type: 'paragraph',
        value:
          'Click on any one of these buttons and Bodhi takes you into the controls relevant to that area. The Level 200 button, for example, brings up audio system control for the various areas of lobby, the conference center, restaurant, elevators, restrooms, and terrace. Click on one of these to choose a music source, change its volume level and view any alarms. There’s also a button that links to any open trouble tickets, and another to an energy report which charts power usage for the area and period you choose.',
      },
      {
        type: 'paragraph',
        value:
          'One of the most powerful screens is the Scheduler, a calendar application that allows managers with the proper authorization to click on any day and time they choose, then change or create scenes, groups, presets, or settings for individual devices using any of the technology under Bodhi’s control. Its pop-up Job Panels use the same logic as the buttons of the Dashboard, but with the ability to set start and end times and choose whether to repeat or not.',
      },
      {
        type: 'paragraph',
        value:
          'In use since the Daytona’s opening in June, 2019, Bodhi has drawn rave reviews from management, who say it greatly simplifies their use of the technology on the property.',
      },
      {
        type: 'paragraph',
        value: `The hotel has drawn rave reviews from guests as well, earning 9.4 out of 10 points on Bookings.com, 9.8 on Hotels.com, 4.9 out of 5 on Marriott.com and Expedia, and 
        5 out of 5 stars on TripAdvisor.`,
      },
      {
        type: 'paragraph',
        value:
          '“Wow!” wrote one guest on TripAdvisor. “I can’t believe this hotel is in Daytona Beach.” “Probably the best hotel in Daytona,” wrote another. A third wrote, “The Daytona Autograph is among the Top 5 hotels in Florida!! Fantastic accommodations, staff and location.”',
      },
    ],
  },
  // NEXUS CLUB
  nexusClub: {
    headImagePath: '/images/project-page-head-section-nexus-club.jpg',
    title: 'Nexus Club, New York',
    subtitle:
      'Simplified monitoring, control and scheduling of key systems across multiple locations',
    bodyItems: [
      {
        type: 'paragraph',
        value: 'NEXUS is reinventing the concept of the health, fitness and social club.',
      },
      {
        type: 'paragraph',
        value:
          'NEXUS Clubs are for the entire family, with daycare, youth and teen areas as well as a fitness center, spa, meeting rooms, restaurants, lounges, and bars built with couples and single adults in mind.',
      },
      {
        type: 'paragraph',
        value:
          'A couple may join a NEXUS club for the fitness center, spa or restaurants, but then find more and more to do there as they start and grow a family.',
      },
      {
        type: 'image',
        value: '/images/project-page-body-section-side-nexus-club.jpg',
      },
      {
        type: 'paragraph',
        value:
          'The facilities, cuisine, service and appointments are all top-tier, and the clubs include the Bodhi technology management system.',
      },
      {
        type: 'paragraph',
        value:
          '“Bodhi simplifies the management of the clubs, while protecting each location from possible damage,” explains Greg Michelier, partner at Software Development LLC.',
      },
      {
        type: 'paragraph',
        value:
          '“On the one hand, Bodhi allows management and staff to adjust and schedule lighting, climate and audio systems throughout the clubs, using any device with an Internet browser.',
      },
      {
        type: 'paragraph',
        value:
          'On the other hand, it detects potential problems, such as leaks in the plumbing or malfunctioning electronic equipment, and sets limits on temperature and audio levels, preventing damage and keeping members more comfortable.” ',
      },
      {
        type: 'paragraph',
        value:
          'So far, Bodhi is installed in the New York and Nassau, Bahamas, clubs, with plans to add it as NEXUS opens new locations in Los Angeles, Toronto, and the Exumas.',
      },
      {
        type: 'heading',
        value: 'The next level',
      },
      {
        type: 'paragraph',
        value:
          'The NEXUS Clubs are the brainchild of an investment group led by Tiger Woods, Justin Timberlake and British financier Joe Lewis, who have set out to provide an outstanding new type of club experience.',
      },
      {
        type: 'paragraph',
        value:
          'In reviewing the New York club, the Robb Report wrote, “Now there’s a club that just might outdo them all [with] two elements that take the lifestyle experience to the next level: a holistic wellness center and family services…',
      },
      {
        type: 'paragraph',
        value:
          '“Take your meetings in a private conference room after dropping the kids off at the daycare center, have lunch at the café, followed by a personal training session, and then finish off the day with a glass of Dom in Shelby, the chic lounge that is named after Timberlake’s Tennessee hometown. You couldn’t do all that at Core Club or Soho House and certainly not at a Playboy Club.”',
      },
      {
        type: 'paragraph',
        value:
          'The New York Club, in Manhattan’s Tribeca neighborhood, offers a 7,800-square-foot fitness center, two studios for private workouts and classes, a spa with five treatment rooms, a hair salon and cryotherapy chamber, plus a dining room, café, traditional and wine bars, a meeting center, a daycare center, kids’ club, game room, and even a Dylan’s Candy Bar.',
      },

      {
        type: 'paragraph',
        value:
          'The Nassau Club, located in the Baha Mar Resort, features a Veuve Clicquot Champagne Bar, a sports bar, indoor gaming area, a central lounge, a private pool surrounded by cabanas, and exquisite views of the island waters.',
      },

      {
        type: 'paragraph',
        value:
          'Michelier says that while the clubs are equipped with the latest audio, video and computer systems, technology can be a two-edged sword.',
      },
      {
        type: 'paragraph',
        value:
          '“Certainly you want to provide your members and guests with the very latest systems, but keeping them running perfectly can be a challenge. By constantly monitoring key technology, Bodhi helps management avoid glitches and downtime, and allows them to spend more time on what really sets them apart: personal service.”',
      },
      {
        type: 'carousel',
        value: [
          {
            title: 'New York: Rose Champagne Bar',
            imagePath: '/images/project-page-body-section-carousel-nexus-club-01.jpg',
          },
          {
            title: 'New York: Club Room',
            imagePath: '/images/project-page-body-section-carousel-nexus-club-02.jpg',
          },
          {
            title: 'New York: The Den teen area',
            imagePath: '/images/project-page-body-section-carousel-nexus-club-03.jpg',
          },
          {
            title: 'New York: The Shelby Bar',
            imagePath: '/images/project-page-body-section-carousel-nexus-club-04.jpg',
          },
          {
            title: 'Nexus Nassau: Pool',
            imagePath: '/images/project-page-body-section-carousel-nexus-club-05.jpg',
          },
        ],
      },
      {
        type: 'heading',
        value: 'Anticipating problems',
      },
      {
        type: 'paragraph',
        value:
          'At NEXUS, Bodhi manages the Crestron processors installed at each property and all of the components that the Crestron systems control. In doing so, Bodhi simplifies the management of the Crestron systems and enhances their functionality.',
      },
      {
        type: 'paragraph',
        value:
          'One key to Bodhi’s value is its creators’ understanding of building and electronic technology. “We know, for example, that most electronic systems will start to draw extra current when they’re not functioning properly,” Michelier explains. “So we designed Bodhi to track the current flow to each component, then send IT or Maintenance an alert should something start operating outside of its specification.”',
      },
      {
        type: 'paragraph',
        value:
          'Another worry Bodhi addresses is the possibility of leaks and floods. “A small leak can go undetected for days or weeks, in the meantime damaging woodwork and plaster while promoting the growth of mold,” he adds.  ',
      },
      {
        type: 'paragraph',
        value:
          'During the hours the clubs are open, staff and guests use water regularly, and while Bodhi monitors the flow, it does not report any issues. But overnight, water use should be zero, and even a tiny flow will indicate a drip or a leak. When Bodhi detects that, it will notify maintenance (via text or email) so that they can address the problem.',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi also includes a ticket tracking system, with every alert automatically creating a trouble ticket. In this way, appropriate managers can track all alerts and make sure they are resolved. Alerts also post to Bodhi’s extensive reporting system, so managers can track a system’s history of trouble, as well as its overall use.',
      },
      {
        type: 'heading',
        value: 'Simplifying control',
      },
      {
        type: 'paragraph',
        value:
          'All of this said, the real power of Bodhi is less in preventing problems as in simplifying the use of various types of technology.',
      },
      {
        type: 'paragraph',
        value:
          'The heart of the Bodhi application is its Dashboard, which provides a simple overview of the clubs’ systems, drilling down from the location to the functional areas (such as Reception or the Spa), then to individual rooms, their Crestron control systems, and other connected systems such as the flow meters. Using any device with a web browser, managers (with appropriate password-protected access) can use the Dashboard to set lighting, temperature and audio levels, check on any alerts and view reports.',
      },
      {
        type: 'paragraph',
        value:
          'One of the most powerful features of Bodhi is its Scheduler, a calendar-based application that allows users to schedule functions for any date and time or range of dates and times, automatically repeating them if desired. So, for example, if the Nassau club  will host a wedding reception Saturday, the catering manager might schedule the audio sources and volume levels, plus any special temperature and lighting levels from her office anytime during planning. Local staff can always make adjustments, if needed, but, generally speaking, technology settings are one less thing to worry about the day of the event.',
      },
      {
        type: 'paragraph',
        value:
          'The investors’ intent, in creating NEXUS, is to establish a new leader in health and wellness. In its ability to enhance the technology and management of the clubs, Bodhi is an important tool helping them meet that goal.',
      },
    ],
  },
  // THINK SIMPLE
  thinkSimple: {
    headImagePath: '/images/project-page-head-section-think-simple.jpg',
    title: 'Think Simple',
    subtitle: 'Enhanced Crestron integration, for clients and their own offices',
    bodyItems: [
      {
        type: 'paragraph',
        value:
          '“Bodhi simplifies and enhances the Crestron technology that is crucial to our business,” says Geoffrey Ranson, Crestron Programmer for technology integrator Think Simple.',
      },
      {
        type: 'paragraph',
        value:
          '“Without taking anything away from what Crestron already does, it adds a very simple scheduling interface, allows us to add sensors and instruments not normally used with Crestron systems, and makes it easier to integrate third-party controllers from Lutron and other suppliers.”',
      },
      {
        type: 'paragraph',
        value:
          '“Bodhi also eliminates the unpleasant surprises that can sometimes happen with even the very best technology,” he adds. “It constantly monitors all of our most important systems, sends alerts when anything moves out of spec, and reminds us to take care of needed repairs.',
      },
      {
        type: 'paragraph',
        value:
          '“In doing all this, it helps us better serve our clients and better maintain the technology installed in our own offices.”',
      },
      {
        type: 'image',
        value: '/images/project-page-body-section-side-think-simple.jpg',
      },
      {
        type: 'heading',
        value: 'A Crestron Platinum Elite Dealer',
      },
      {
        type: 'paragraph',
        value:
          'Think Simple is a full service audio/video integration firm serving businesses, homes, hotels, and schools in Florida, the Bahamas and New York. They are known for their superb personal service and extremely high standards, and they are an authorized dealer/integrator for Crestron, Lutron, Samsung, QSC, Cisco and other leading firms.',
      },
      {
        type: 'paragraph',
        value:
          '“The biggest benefit for us is how easily and completely Bodhi works with Crestron, our most important partner,” Ranson says.',
      },
      {
        type: 'paragraph',
        value:
          'One of the best things about a Crestron system, he explains, is its ability to automate repetitive tasks. Say the integrator sets up a background music system for a restaurant. He or she can program the Crestron processor to turn on the system in the morning, select different sources and volume levels for breakfast, lunch and dinner services, then shut it down at the end of the day. The programmer can do the same thing with lighting, video, climate, shading: anything a Crestron system can control.',
      },
      {
        type: 'paragraph',
        value:
          'As great as that is, if the client wants to change the schedule frequently, it can be a problem, because the programmer will have to update and recompile the code each time.',
      },
      {
        type: 'paragraph',
        value:
          'But Bodhi allows the integrator to modify such schedules quickly and dynamically, from any computer, phone or tablet with an Internet browser with the proper login – even from home.',
      },
      {
        type: 'paragraph',
        value:
          'It’s so easy, in fact, that Think Simple clients regularly set up and modify their own automated scenes and presets using its simple, calendar-based interface. Some, in fact, save new presets for almost every special event they hold, and they have found they can set them up during the planning phase, often days or weeks in advance!',
      },
      {
        type: 'paragraph',
        value:
          'Bodhi also allows Think Simple to enhance its Crestron systems with sensors and instruments that are not necessarily designed to work with Crestron. “For example, we’re using low-cost Z-Wave and Zigbee temperature, humidity and water flow sensors to monitor for conditions that commonly cause damage and downtime. We also use Bodhi occupancy sensors, tied to our Crestron lighting and climate controls, to save energy.”',
      },
      {
        type: 'paragraph',
        value:
          'One of the most useful capabilities of Bodhi, Ranson says, is its ability to monitor the various components installed with a Crestron system, then send Think Simple and the client alerts any time anything slips out of spec. “This is a tremendous add-on for our remote monitoring contracts,” he explains. “Now the equipment tells us it’s about to break, and so we can reset or repair it before it causes a disruption.”',
      },
      {
        type: 'paragraph',
        value:
          'Think Simple was the first dealer/integrator to sign with Bodhi, and they have been using it with clients and in their own offices since 2014.',
      },
      {
        type: 'paragraph',
        value:
          '“It’s been a tremendous help,” Ranson says. “Clients are happier, our staff is happier, and we’re making more money!”',
      },
      {
        type: 'paragraph',
        value:
          '“In terms of our integration business, and our own office management, Bodhi is a win-win!”',
      },
      {
        type: 'carousel',
        value: [
          {
            title: 'Think Simple Fort Lauderdale',
            imagePath: '/images/project-page-body-section-carousel-think-simple-01.jpg',
          },
          {
            title: 'Think Simple Orlando',
            imagePath: '/images/project-page-body-section-carousel-think-simple-02.jpg',
          },
          {
            title: 'Think Simple Office',
            imagePath: '/images/project-page-body-section-carousel-think-simple-03.jpg',
          },
          {
            title: 'Think Simple Office',
            imagePath: '/images/project-page-body-section-carousel-think-simple-04.jpg',
          },
        ],
      },
    ],
  },
  // ISLEWORTH RESIDENCES
  isleworthResidences: {
    headImagePath: '/images/project-page-head-section-isleworth-residences.jpg',
    title: 'Isleworth Residences ',
    subtitle: 'Simplified setup and management of an extensive Crestron lighting system',
    bodyItems: [
      {
        type: 'paragraph',
        value:
          'If you could build the home of your dreams, without any worries about money or time, what would it be?  ',
      },
      {
        type: 'paragraph',
        value:
          'Large enough to entertain in style, yet a great place to chill with your family and friends? Beautifully built, using the best materials and craftsmanship?',
      },
      {
        type: 'paragraph',
        value:
          'With great technology, including elegant lighting and shading, exceptional video and sound, and super-simple control and automation?',
      },
      {
        type: 'paragraph',
        value:
          'This extraordinary, 32,850 square-foot home in the luxury community of Isleworth, outside of Orlando, Florida, has all of that and more, including the Bodhi building operation system to simplify setup and ongoing maintenance of its extensive lighting systems. ',
      },
      {
        type: 'image',
        value: '/images/project-page-body-section-side-isleworth-residences.jpg',
      },
      {
        type: 'heading',
        value: 'Making it simple ',
      },
      {
        type: 'paragraph',
        value:
          'Keeping track of all the technology in a home can be challenging, whether for the homeowner, the builder or the integrator. That’s especially true in this large and complex residence.',
      },
      {
        type: 'paragraph',
        value:
          '“Bodhi helped us in two important ways,” explains Joe Vaccarino, the Orlando branch manager for technology integrator Think Simple. ',
      },
      {
        type: 'paragraph',
        value:
          '“First, we have more than 280 zones of dimming in this home, spread out over three floors plus extensive grounds. Using Bodhi, we cut our programming and setup time by more than half.”',
      },
      {
        type: 'paragraph',
        value:
          '“Second, Bodhi allows us to quickly identify any component that may be slipping out of spec and direct the builder to repair or replace it before it can fail. If we have an outage, we can identify the problem in minutes, minimizing downtime as well as labor for troubleshooting. In this way, Bodhi saves us hour after hour on the ongoing maintenance of the house.”',
      },
      {
        type: 'heading',
        value: 'Built on a grand scale',
      },
      {
        type: 'paragraph',
        value:
          'The home itself is extraordinary, one of the largest and best appointed in central Florida.',
      },
      {
        type: 'paragraph',
        value:
          'It includes an extensive use of limestone and marble, a striking porte-cochère and a color-changing fountain, 24-foot ceilings, an Olympic-sized swimming pool and expansive indoor and outdoor entertainment spaces. Still, it’s clean, open and contemporary.',
      },
      {
        type: 'paragraph',
        value:
          'Design and construction took more almost four years. “We were responsible for all of the technology in this house, from the wireless network to the acoustics in the theater,” explains Will Gilbert, partner at Think Simple. It was one of the first large homes to use Crestron NVX; one of the first to use Crestron Horizon keypads; and the first to use a Crestron motorized shading system with a 24-foot drop.',
      },
      {
        type: 'paragraph',
        value:
          'The exterior of the house is something special, especially at night. Custom-made LED fixtures highlight the home’s features, while color-changing lights on front and back columns, the fountain, a fire pit and a spa in the back and on all stairways (interior and exterior) give it a very special look and feel.',
      },
      {
        type: 'paragraph',
        value:
          'Think Simple split the lighting control into sections, using six Crestron processors for the majority of fixtures (inside and out) plus a Nicolaudie DMX controller for the color-changing lights.',
      },
      {
        type: 'paragraph',
        value:
          '“Programming a lighting system this large, even with Crestron’s simplified programming language, would normally be a huge task,” Vaccarino explains. Then, too, making the inevitable changes that the homeowner might ask for and recompiling the code each time, would also be very time-consuming.',
      },
      {
        type: 'paragraph',
        value:
          'But Bodhi greatly streamlined the project. Now instead of having to write out instructions using a programming language, the Think Simple team chose and set up each device and each scene using drop-down menus. Instead of hard-coding the times for lighting to turn on and off automatically, they set them up on a simple, calendar-based schedule.',
      },
      {
        type: 'paragraph',
        value:
          'And they could do their work from any Internet-connected computer, tablet or phone, either on-site at the home or from anywhere with an Internet connection.',
      },
      {
        type: 'carousel',
        value: [
          {
            title: 'Isleworth Residences',
            imagePath: '/images/project-page-body-section-carousel-isleworth-residences-01.jpg',
          },
          {
            title: 'Isleworth Residences',
            imagePath: '/images/project-page-body-section-carousel-isleworth-residences-02.jpg',
          },
          {
            title: 'Isleworth Residences',
            imagePath: '/images/project-page-body-section-carousel-isleworth-residences-03.jpg',
          },
        ],
      },
      {
        type: 'heading',
        value: 'Ongoing maintenance',
      },
      {
        type: 'paragraph',
        value:
          'While it’s been more than a year since the house was finished, “we haven’t had any problems with the lighting fixtures, dimmers or control units, and Bodhi will protect us as they age,” Vaccarino notes.',
      },
      {
        type: 'paragraph',
        value:
          'That said, troubleshooting something as simple as a tripped breaker can be a problem for the builder, who holds a maintenance contract on the house, just because there are so many circuits and electrical panels.',
      },
      {
        type: 'paragraph',
        value:
          '“One nice thing about Bodhi is that I get feedback on whether a dimming module is on or not, and, if it’s on, I know the problem is with the breaker, not the module. So if the builder calls me and tells me some of the lights don’t work, I can tell them in a minute if the breaker has blown, and then which panel to visit to reset it.”',
      },
      {
        type: 'paragraph',
        value:
          'In the same way, if the homeowners or their staff want to change the time that the entryway lights turn on or change the lighting levels on the pool deck, they can call and a Think Simple technician can make the change immediately, no matter where he or she might be.',
      },
      {
        type: 'paragraph',
        value:
          'The biggest advantage, though, comes from the use of Bodhi’s Predictive Maintenance feature. Predictive Maintenance tracks the age of each component, its hours of use, and its adherence to its manufacturer’s specifications. Should a component begin to draw more AC power than specified, for example, Bodhi will detect that and send an alert to Think Simple to service or replace that item.',
      },
      {
        type: 'paragraph',
        value:
          '“Of course, we can assign logins with whatever level of access is needed to the homeowner, his staff, the builder and others who regularly work on the home,” Vaccarino adds.',
      },
      {
        type: 'paragraph',
        value:
          '“So far, we’ve handled any lighting control questions under our service contract, but it would be easy enough for others to jump in if there was a need.',
      },
      {
        type: 'paragraph',
        value:
          '“That said, with Bodhi the lighting systems pretty much take care of themselves. It really has made this large and complex project simple.”',
      },
    ],
  },
};

// SOLUTIONS PAGE CONTENT
export const newPage = {
  hospitality: {
    intro: {
      textColor: '#ffffff',
      title: 'Hospitality',
      description: (
        <Fragment>
          Delight your guests while saving energy, <br /> labor, and headaches
        </Fragment>
      ),
      mobileBackgroundOverlayColor: '#172738',
      backgroundImagePath: '/images/solution-page-intro-section-hospitality.jpg',
    },
    examples: [
      {
        value: 'Guestrooms',
        imagePath: '/images/solution-page-examples-section-hospitality-01.jpg',
        secondaryImagePath: '/images/solution-page-examples-section-hospitality-01-layout.jpg',
        secondaryImageText: 'View typical guestroom configurations',
        smartLink: '/hotel-rooms/smart',
        smartLinkText: 'Smart',
        premiumLink: '/hotel-rooms/premium',
        premiumLinkText: 'Premium',
        luxuryLink: '/hotel-rooms/luxury',
        luxuryLinkText: 'Luxury',
        items: [
          {
            value: 'Save at least 35% - 45% of total energy used in guestrooms',
            items: [
              {value: 'Turn off lights, turn down climate, close shades when guests are away'},
            ],
          },
          {
            value: 'Guest app includes keyless entry, room controls, amenity access',
            items: [
              {value: 'Browser-based, so nothing to install'},
              {value: 'Guests unlock room with smart phone (works with most existing locks)'},
              {value: 'Simplifies service requests: housekeeping, valet, concierge, reservations '},
              {value: 'Phone acts as remote for TV, entertainment systems'},
              {value: 'Simply give guests a link on check in'},
              {value: 'Or integrate with your branded app'},
            ],
          },
          {
            value: 'Welcome scenes surprise and delight guests',
            items: [{value: 'Can include lighting, shading and climate presets, music and video'}],
          },
          {
            value: 'Manages and enhances Crestron and Lutron or works on its own',
          },
          {
            value: 'Alexa voice controls now a practical option for guestrooms',
          },
          {
            value:
              'Presence detection service assists housekeeping & maintenance staff to know when the room is vacant',
          },
          {
            value: 'Predictive maintenance minimizes technology downtime & guest frustration',
          },
          {
            value:
              'Integrates Property Management System and room access (even with low-cost platforms including Crestron Home and Lutron Caséta)',
          },
          {
            value:
              'Low-cost Bodhi Thermostat combines climate control, occupancy sensor and technology hub',
          },
        ],
      },
      {
        value: (
          <Fragment>
            Restaurants, Bars {'&'}
            <br />
            Common Areas
          </Fragment>
        ),
        imagePath: '/images/solution-page-examples-section-hospitality-02.jpg',
        items: [
          {value: 'Manages and automates music, video, lighting, shading, and climate'},
          {
            value: 'Simple, real-time controls free management and staff time during rush periods',
          },
          {
            value:
              'Calendar-based scheduler automates settings changes by time of day, day of week, & season',
          },
          {
            value: 'Scheduler allows managers to set up in advance for special events',
          },
          {
            value: 'Predictive maintenance minimizes downtime, improves service',
          },
          {
            value: 'Result is more focus on guests and a better experience',
          },
        ],
      },
      {
        value: 'Event Center',
        imagePath: '/images/solution-page-examples-section-hospitality-03.jpg',
        items: [
          {
            value:
              'Calendar-based scheduler allows technology setup during planning phase of each event',
            items: [
              {value: 'AV routing, room combination & division, volume levels, lighting & shading'},
            ],
          },
          {
            value: 'Local controls simplify last-minute adjustments and changes',
          },
          {
            value: 'Predictive maintenance minimizes downtime, improves service',
          },
          {
            value: 'Reporting package tracks use of, and value of each system installed',
          },
        ],
      },
    ],
    caseStudies: [
      {
        value: 'Art Ovation',
        imagePath: '/images/solution-page-case-studies-section-hospitality-01.jpg',
        link: '/projects/art-ovation-hotel',
        items: [
          'Common areas: music, lighting, & climate',
          'Lobby & rooftop bars: music & lighting',
          'Performance audio: lobby stage & rooftop DJs',
          'Ballroom: background music & AV routing',
        ],
      },
      {
        value: 'Daytona Hotel',
        imagePath: '/images/solution-page-case-studies-section-hospitality-02.jpg',
        link: '/projects/the-daytona',
        items: [
          'Common areas: music, lighting, & climate',
          'Restaurant & bar: music & lighting',
          'Event center: background music & AV routing',
          'Digital signage: scheduling & routing',
        ],
      },
      {
        value: 'Nexus Club',
        link: '/projects/nexus-club',
        imagePath: '/images/solution-page-case-studies-section-hospitality-03.jpg',
        items: [
          'Throughout the facility: leak detection',
          'Lobby: Lighting, climate, audio & video',
          'Restaurants & fitness center: Music, lighting & climate',
        ],
      },
    ],
  },
  residential: {
    intro: {
      textColor: '#ffffff',
      title: 'Residential',
      description: (
        <Fragment>
          Protect your property while <br />
          saving energy and maintenance costs.
        </Fragment>
      ),
      backgroundImagePath: '/images/solution-page-intro-section-residential.jpg',
      mobileBackgroundOverlayColor: '#172738',
    },
    examples: [
      {
        value: 'Single Family Homes',
        imagePath: '/images/solution-page-examples-section-residential-01.jpg',
        items: [
          {
            value:
              'Leak, flood, and mold protection minimizes the greatest sources of damage for homeowners',
          },
          {
            value:
              'Together these issues account for 57% of insurance claims and 71% of dollar losses',
          },
          {
            value: 'Occupancy-based energy management saves at least 10% of total energy bills',
          },
          {
            value:
              'Simplified control of climate, lighting and entertainment systems for greater satisfaction',
          },
          {
            value: 'Predictive maintenance minimizes downtime & homeowner frustration',
          },
          {
            value: 'Manages and enhances Crestron, Lutron, and Alexa or works on its own',
          },
          {
            value: 'Ideal for larger homes with more extensive technology',
          },
        ],
      },
      {
        value: (
          <Fragment>
            Multi-home developments <br /> and associations
          </Fragment>
        ),
        imagePath: '/images/solution-page-examples-section-residential-02.jpg',
        items: [
          {
            value: 'Scales to fit large or small developments',
          },
          {
            value: 'Homeowners can set up their own presets, scenes and schedules',
          },
          {
            value:
              'HOA schedules and automates lighting, signage, other systems in community areas and clubhouses',
          },
          {
            value: 'Greatly simplifies management and automation of various technology platforms',
          },
        ],
      },
    ],
    caseStudies: [
      {
        value: 'Isleworth, Florida',
        imagePath: '/images/solution-page-case-studies-section-residential-01.jpg',
        link: '/projects/isleworth-residences',
        items: [
          'Manages 280 zones of Crestron lighting',
          'Simplifies automation of outdoor and indoor lighting',
          'Predictive maintenance anticipates and prevents component failures',
        ],
      },
    ],
  },
  mdu: {
    intro: {
      textColor: '#000000',
      title: 'Multi Dwelling Unit',
      description: 'Prevent losses and save energy while increasing the enjoyment of homeowners',
      backgroundImagePath: '/images/solution-page-intro-section-mdu.jpg',
      mobileBackgroundOverlayColor: '#C0E1F7',
    },
    examples: [
      {
        value: 'Condos, Villas, Houses',
        imagePath: '/images/solution-page-examples-section-mdu-01.jpg',
        items: [
          {
            value:
              'Leak, flood, and mold protection minimizes the greatest risk of damage for homeowners',
            items: [
              {
                value:
                  'Together these issues account for 57% of insurance claims and 71% of dollar losses',
              },
            ],
          },
          {
            value: 'Occupancy-based energy management saves at least 10% of total energy bills',
          },
          {
            value:
              'Simplified control of building and entertainment systems means greater enjoyment and satisfaction',
          },
          {
            value: 'Predictive maintenance minimizes downtime & homeowner frustration',
          },
          {
            value: 'Manages and enhances Crestron, Lutron, and Alexa or works on its own',
          },
        ],
      },
      {
        value: (
          <Fragment>
            Restaurants, Clubs, {'&'} <br /> and Common Areas
          </Fragment>
        ),
        imagePath: '/images/solution-page-examples-section-mdu-02.jpg',
        items: [
          {
            value: 'Manages and automates music, video, lighting, shading, and climate',
          },
          {
            value: 'Simple, real-time controls free management and staff time during rush periods',
          },
          {
            value:
              'Calendar-based scheduler automates settings changes by time of day, day of week, & season',
          },
          {
            value: 'Scheduler allows managers to set up in advance for special events',
          },
          {
            value: 'Predictive maintenance minimizes downtime, improves service',
          },
          {
            value: 'Result is more focus on guests and a better experience',
          },
        ],
      },
      {
        value: 'In Outdoor Areas',
        imagePath: '/images/solution-page-examples-section-mdu-03.jpg',
        items: [
          {
            value: 'Astronomical clock automates lighting by time of sunset and sunrise',
          },
          {
            value:
              'Calendar-based scheduler makes it easy to adjust for seasonal changes & special events',
          },
          {
            value: 'Door-open alerts enhance building security',
          },
        ],
      },
    ],
    caseStudies: [
      // {
      //   value: 'Albany',
      //   imagePath: '/images/solution-page-case-studies-section-mdu-01.jpg',
      //   link: '/projects/albany',
      //   items: [
      //     'More than 160 residences in 7 condo buildings + villas and detached homes',
      //     'Flood, leak and mold prevention',
      //     'Door-open protection',
      //     'Occupancy-based home & away modes control climate, lighting, & shading',
      //   ],
      // },
    ],
  },
  commercial: {
    intro: {
      textColor: '#000000',
      title: 'Commercial',
      description: 'Help your staff stay productive while saving energy, maintenance, and downtime',
      backgroundImagePath: '/images/solution-page-intro-section-commercial.jpg',
      mobileBackgroundOverlayColor: '#C0E1F7',
    },
    examples: [
      {
        value: 'Office and Common Areas',
        imagePath: '/images/solution-page-examples-section-commercial-01.jpg',
        items: [
          {
            value: 'Save at least 10% of total energy use',
            items: [
              {
                value: 'Turn off lights, turn down climate, close shades by time and occupancy',
              },
            ],
          },
          {
            value:
              'Calendar-based scheduler simplifies day-of-week and seasonal adjustments for further savings',
          },
          {
            value: 'IoT sensors protect against water damage, mold and security risks',
          },
          {
            value: 'Reporting package tracks use of, and value of each system installed',
          },
          {
            value: 'Predictive maintenance minimizes system downtime, improves service',
          },
          {
            value: 'Manages and enhances Crestron, Lutron lighting and climate control systems',
          },
        ],
      },
      {
        value: 'In Conference Rooms',
        imagePath: '/images/solution-page-examples-section-commercial-02.jpg',
        items: [
          {
            value: 'Manages and enhances Crestron-based communication and presentation systems',
          },
          {
            value: 'Calendar-based scheduler simplifies advanced setup for special events',
          },
          {
            value: 'Local controls simplify last-minute adjustments and changes',
          },
          {
            value: 'Predictive maintenance minimizes downtime, improves service',
          },
          {value: 'Reporting package tracks use of, and value of each system installed'},
        ],
      },
    ],
    caseStudies: [],
  },
};
// HOTEL-ROOMS PAGES
export const hotelRooms = {
  // SMART HOTEL ROOM
  smartHotel: {
    roomRenderingImagePath: '/images/hotel-rooms-page-smart-room-rendering.jpg',
    description: [
      'While there are many ways to use Bodhi, this sketch shows a basic, but complete configuration in a typical hotel room.',
      'Here the Bodhi Thermostat Bridge provides HVAC control plus connections with the Bodhi Cloud application and sensors installed in the room. Management can monitor and control the room through Bodhi Cloud, while guests have room control and Bodhi access via the Bodhi Guest App and wall-mounted lighting keypad.',
      'With this setup, your property will save at least 35-45% of guestroom energy costs, protect against expensive leak, flood and mold damage, which insurance industry studies show are involved in 57% of real estate insurance claims and 71% of dollar losses, while giving guests outstanding convenience, control, and access to hotel services and amenities.',
    ],
    lineDrawingImagePath: '/images/hotel-rooms-page-smart-line-drawing.jpg',
    downloadLinkTextRegular: 'Download our ',
    downloadLinkTextUnderlined: 'Typical Hotel Room Diagram Brochure',
    downloadLink: 'https:/gobodhi.com/downloads/bodhi-hotel-diagrams.pdf',
  },
  // PREMIUM HOTEL ROOM
  premiumHotel: {
    roomRenderingImagePath: '/images/hotel-rooms-page-premium-room-rendering.jpg',
    description: [
      'While there are many ways to use Bodhi, this sketch shows a typical configuration in a premium hotel room.',
      'Here the Bodhi Thermostat Bridge provides HVAC control plus connections with the Bodhi Cloud application and Bodhi and Crestron devices installed in the room. Management can monitor and control the room through Bodhi Cloud, while guests have room control and Bodhi access via the Bodhi Guest App, Alexa voice control, and wall-mounted Crestron keypads.',
      'With this setup, your property will save at least 35-45% of guestroom energy costs, protect against expensive leak, flood and mold damage, which insurance industry studies show are involved in 57% of real estate insurance claims and 71% of dollar losses, while giving guests premium convenience, control, and access to hotel services and amenities.',
    ],
    lineDrawingImagePath: '/images/hotel-rooms-page-premium-line-drawing.jpg',
    downloadLinkTextRegular: 'Download our ',
    downloadLinkTextUnderlined: 'Typical Hotel Room Diagram Brochure',
    downloadLink: 'https://gobodhi.com/downloads/bodhi-hotel-diagrams.pdf',
  },
  // LUXURY HOTEL ROOM
  luxuryHotel: {
    roomRenderingImagePath: '/images/hotel-rooms-page-luxury-room-rendering.jpg',
    description: [
      'While there are many ways to use Bodhi, this sketch shows a typical configuration in a luxury hotel room or suite.',
      'Here a Crestron touch panel, wall-mounted Crestron keypads, Amazon Alexa and the Bodhi Guest App provide simplified room controls, while management monitors, controls and schedules Crestron and Bodhi devices through Bodhi Cloud.',
      'With this setup, your property will save at least 35-45% of guestroom energy costs, protect against expensive leak, flood and mold damage, which insurance industry studies show are involved in 57% of real estate insurance claims and 71% of dollar losses, while giving guests the ultimate in convenience, control, and access to hotel services and amenities.',
    ],
    lineDrawingImagePath: '/images/hotel-rooms-page-luxury-line-drawing.jpg',
    downloadLinkTextRegular: 'Download our ',
    downloadLinkTextUnderlined: 'Typical Hotel Room Diagram Brochure',
    downloadLink: 'https://gobodhi.com/downloads/bodhi-hotel-diagrams.pdf',
  },
};

// EVENTS PAGES
export const events = {
  // GENERIC EVENT
  generic: {
    introImagePath: '/images/events-page-intro-image.jpg',
    introImageText: 'Building Operation Simplified',
    descriptionTitle: 'It was great to see you at the event',
    descriptionTitleMobile: (
      <Fragment>
        It was great to see you <br /> at the event
      </Fragment>
    ),
    descriptionArray: [
      'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all the technology on your property from a single interface - from lighting, climate, water, electrical metering, audio/video and other building technologies.',
      "Best of all, it's so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.",
    ],
    descriptionTextArray: [
      {
        text: 'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all the technology on your property from a single interface - from lighting, climate, water, electrical metering, audio/video and other building technologies.',
        bulletPoints: [
          'Save energy: up to 20% for commercial and residential, up to 45% in hotels',
          'Mitigate risk from floods, leaks and humidity',
          'Shrink maintenance costs through predictive maintenance',
          'Improve client satisfaction and staff productivity',
        ],
      },
      {
        text: "Best of all, it's so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.",
        bulletPoints: undefined,
      },
    ],
    contactIdentifier: 'Generated from generic event page',
    contactTitle: 'Get in touch',
    contactDescriptionArray: [
      'Would you like to learn more about Bodhi?',
      'Please browse our website or send us a quick message',
    ],
    bodhiLogo: '',
    eventLogo: '',
  },
  // BODHI EVENT
  bodhi: {
    introImagePath: '/images/events-page-intro-image.jpg',
    introImageText: 'Building Operation Simplified',
    descriptionTitle: 'It was great to see you at the Bodhi event',
    descriptionTitleMobile: (
      <Fragment>
        It was great to see you <br /> at the Bodhi event
      </Fragment>
    ),
    descriptionArray: [
      'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all the technology on your property from a single interface - from lighting, climate, water, electrical metering, audio/video and other building technologies.',
      "Best of all, it's so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.",
    ],
    descriptionTextArray: [
      {
        text: 'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all the technology on your property from a single interface - from lighting, climate, water, electrical metering, audio/video and other building technologies.',
        bulletPoints: [
          'Save energy: up to 20% for commercial and residential, up to 45% in hotels',
          'Mitigate risk from floods, leaks and humidity',
          'Shrink maintenance costs through predictive maintenance',
          'Improve client satisfaction and staff productivity',
        ],
      },
      {
        text: "Best of all, it's so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.",
        bulletPoints: undefined,
      },
    ],
    contactIdentifier: 'generated from Bodhi event page',
    contactTitle: 'Get in touch',
    contactDescriptionArray: [
      'Would you like to learn more about Bodhi?',
      'Please browse our website or send us a quick message',
    ],
    bodhiLogo: '',
    eventLogo: '',
  },
  // HERO 2022 EVENT
  hero2022: {
    introImagePath: '/images/events-page-intro-image.jpg',
    introImageText: 'Building Operation Simplified',
    descriptionTitle: 'It was great to see you at the Hero Challenge',
    descriptionTitleMobile: (
      <Fragment>
        It was great to see you <br /> at the Hero Challenge
      </Fragment>
    ),
    descriptionArray: [
      'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all the technology on your property from a single interface - from lighting, climate, water, electrical metering, audio/video and other building technologies.',
      "Best of all, it's so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.",
    ],
    descriptionTextArray: [
      {
        text: 'Bodhi is a cloud-based building operation platform that centrally monitors, analyzes, schedules and controls all the technology on your property from a single interface - from lighting, climate, water, electrical metering, audio/video and other building technologies.',
        bulletPoints: [
          'Save energy: up to 20% for commercial and residential, up to 45% in hotels',
          'Mitigate risk from floods, leaks and humidity',
          'Shrink maintenance costs through predictive maintenance',
          'Improve client satisfaction and staff productivity',
        ],
      },
      {
        text: "Best of all, it's so simple that any property manager, engineer, concierge or homeowner will be able to begin using it immediately.",
        bulletPoints: undefined,
      },
    ],
    contactIdentifier: 'Generated from hero 2022 event page',
    contactTitle: 'Get in touch',
    contactDescriptionArray: [
      'Would you like to learn more about Bodhi?',
      'Please browse our website or send us a quick message',
    ],
    bodhiLogo: '',
    eventLogo: '',
  },
};

// TERMS OF SERVICE PAGE CONTENT
export const termsOfService = {
  items: [
    {
      type: 'body',
      value: 'Last Updated: 06/08/2022.',
    },
    {
      type: 'heading',
      value: `PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR WEBSITE (THE "SITE") OR OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITE OR OUR SERVICES.`,
    },
    {
      type: 'body',
      value: `The present terms and conditions (this "Agreement" or "Terms") is a legal agreement between you and Software Development LLC (hereinafter " Software Dev"), a company duly organized and validly existing, located at 6365 NW 6th Way Ste 170, Fort Lauderdale, Florida 33309. This Agreement annuls and voids all previous agreements.`,
    },
    {
      type: 'heading',
      value: 'OVERVIEW',
    },
    {
      type: 'body',
      value:
        'The Site (https://app.gobodhi.com) is operated by Software Dev. Throughout the Site, the terms "we", "us" and "our" refer to Software Dev. Software Dev offers this Site, including all information, tools and services available from this Site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.',
    },
    {
      type: 'body',
      value:
        'By visiting our Site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms apply to all users of the Site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content. In the event of an inconsistency between this Agreement and any additional terms or policies referenced herein, the provisions of the additional terms or policies shall control.',
    },
    {
      type: 'body',
      value:
        'Please read these Terms carefully before accessing or using our Site. By accessing or using any part of the Site, you agree to be bound by these Terms. If you do not agree to all the Terms of this Agreement, then you may not access the Site or use any Service. If these Terms are considered an offer, acceptance is expressly limited to these Terms.',
    },
    {
      type: 'body',
      value:
        'Any new features or tools which are added to the current store shall also be subject to the Terms. You can review the most current version of the Terms at any time on this page. We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes. Your continued use of or access to the Site following the posting of any changes constitutes acceptance of those changes.',
    },
    {
      type: 'heading',
      value: 'SECTION 1 - GENERAL TERMS',
    },
    {
      type: 'body',
      value:
        'By agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this Site.',
    },
    {
      type: 'body',
      value:
        'You may not use our products or Site for any illegal or unauthorized purpose nor may you, in the use of our products or Site, violate any laws in your jurisdiction (including but not limited to motor vehicle laws).',
    },
    {
      type: 'body',
      value: 'You must not transmit any worms or viruses or any code of a destructive nature.',
    },
    {
      type: 'body',
      value:
        'A breach or violation of any of the Terms will result in an immediate termination of your account and right to use our Service.',
    },
    {
      type: 'body',
      value:
        'We have the right, but not the obligation, to take any of the following actions in our sole discretion at any time and for any reason without giving you any prior notice:',
    },
    {
      type: 'numbered-list',
      value: {
        items: [
          {
            value: 'Restrict, suspend or terminate your access to all or any part of our Site;',
          },
          {
            value: 'Change, suspend or discontinue all or any part of our products or Site;',
          },
          {
            value:
              'Refuse, move, or remove any content that is available on all or any part of our Site;',
          },
          {
            value: 'Deactivate or delete your accounts;',
          },
          {
            value: 'Establish general practices and limits concerning use of our Site.',
          },
        ],
      },
    },
    {
      type: 'body',
      value:
        'You agree that we will not be liable to you or any third party for taking any of these actions.',
    },
    {
      type: 'body',
      value:
        'You understand and agree that our Site may include communications such as service announcements and administrative or legal notices from us. Please note that you cannot opt out of receiving these notices.',
    },
    {
      type: 'body',
      value:
        'You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.',
    },
    {
      type: 'body',
      value:
        'You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of the Site, or access to the Site or any contact on the Site, without express written permission by us.',
    },
    {
      type: 'body',
      value:
        'You may not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Software Dev content is not for resale. Use of the Site does not entitle users to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Software Dev and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Software Dev or our licensors except as expressly authorized by these Terms.',
    },
    {
      type: 'heading',
      value: 'SECTION 2 - CREATING AN ACCOUNT',
    },
    {
      type: 'body',
      value: `Once you create an account with us, you are registered on the Software Dev Site. The terms "member," "membership," and "account" all refer to this registration as a member on Software Dev's Site. If you are merely surfing or browsing through the Site and have not yet created an account, your use of the Site is still subject to this Agreement; if you do not agree to this Agreement, do not use the Site.`,
    },
    {
      type: 'body',
      value: `When you create an account, you will provide a unique username and email. We will also ask you to create a password. Because any activities that occur under your username or password are your responsibility it is important for you to keep your username and/or password secure. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Software Dev is not responsible for third party access to your account that results from theft or misappropriation of your account. Notify us immediately if you believe that someone has used your username, email, or password without your authorization.`,
    },
    {
      type: 'body',
      value: `Furthermore, the registering party hereby acknowledges, understands and agrees to:.`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `furnish factual, correct, current and complete information with regards to yourself as may be requested by the data registration process, and.`,
          },
          {
            value: `maintain and promptly update your registration and profile information in an effort to maintain accuracy and completeness at all times.`,
          },
        ],
      },
    },
    {
      type: 'body',
      value:
        'If anyone knowingly provides any information of a false, untrue, inaccurate or incomplete nature, Software Development LLC will have sufficient grounds and rights to suspend or terminate the member in violation of this aspect of the Agreement, and as such refuse any and all current or future use of Software Development LLC Services, or any portion thereof.',
    },
    {
      type: 'heading',
      value: 'SECTION 3 - CONDUCT',
    },
    {
      type: 'body',
      value:
        'As a user or member of the Site, you herein acknowledge, understand and agree that all information, text, software, data, photographs, music, video, messages, tags or any other content, whether it is publicly or privately posted and/or transmitted, is the expressed sole responsibility of the individual from whom the content originated. In short, this means that you are solely responsible for any and all content posted, uploaded, emailed, transmitted or otherwise made available by way of the Software Dev Services, and as such, we do not guarantee the accuracy, integrity or quality of such content. It is expressly understood that by use of our Services, you may be exposed to content including, but not limited to, any errors or omissions in any content posted, and/or any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted or otherwise made available by Software Dev.',
    },
    {
      type: 'body',
      value: `Furthermore, you herein agree not to make use of Software Development LLC's Services for the purpose of:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `uploading, posting, emailing, transmitting, or otherwise making available any content that shall be deemed unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or invasive of another's privacy or which is hateful, and/or racially, ethnically, or otherwise objectionable;`,
          },
          {
            value: `causing harm to minors in any manner whatsoever;`,
          },
          {
            value: `impersonating any individual or entity, including, but not limited to, any Software Dev officials, forum leaders, guides or hosts or falsely stating or otherwise misrepresenting any affiliation with an individual or entity;`,
          },
          {
            value: `forging captions, headings or titles or otherwise offering any content that you personally have no right to pursuant to any law nor having any contractual or fiduciary relationship with;`,
          },
          {
            value: `uploading, posting, emailing, transmitting or otherwise offering any such content that may infringe upon any patent, copyright, trademark, or any other proprietary or intellectual rights of any other party;`,
          },
          {
            value: `uploading, posting, emailing, transmitting or otherwise offering any content that you do not personally have any right to offer pursuant to any law or in accordance with any contractual or fiduciary relationship;`,
          },
          {
            value: `uploading, posting, emailing, transmitting, or otherwise offering any unsolicited or unauthorized advertising, promotional flyers, "junk mail," "spam," or any other form of solicitation, except in any such areas that may have been designated for such purpose;`,
          },
          {
            value: `uploading, posting, emailing, transmitting, or otherwise offering any source that may contain a software virus or other computer code, any files and/or programs which have been designed to interfere, destroy and/or limit the operation of any computer software, hardware, or telecommunication equipment;`,
          },
          {
            value: `disrupting the normal flow of communication, or otherwise acting in any manner that would negatively affect other users' ability to participate in any real time interactions;`,
          },
          {
            value: `interfering with or disrupting any Software Development LLCServices, servers and/or networks that may be connected or related to our website, including, but not limited to, the use of any device software and/or routine to bypass the robot exclusion headers;`,
          },
          {
            value: `intentionally or unintentionally violating any local, state, federal, national or international law, including, but not limited to, rules, guidelines, and/or regulations decreed by the U.S. Securities and Exchange Commission, in addition to any rules of any nation or other securities exchange, that would include without limitation, the New York Stock Exchange, the American Stock Exchange, or the NASDAQ, and any regulations having the force of law;`,
          },
          {
            value: `providing informational support or resources, concealing and/or disguising the character, location, and or source to any organization delegated by the United States government as a "foreign terrorist organization" in accordance to Section 219 of the Immigration Nationality Act;`,
          },
          {
            value: `"stalking" or with the intent to otherwise harass another individual; and/or`,
          },
          {
            value: `collecting or storing of any personal data relating to any other member or user in connection with the prohibited conduct and/or activities which have been set forth in the aforementioned paragraphs.`,
          },
        ],
      },
    },
    {
      type: 'body',
      value: `Software Development LLC herein reserves the right to pre-screen, refuse and/or delete any content currently available through our Services. In addition, we reserve the right to remove and/or delete any such content that would violate the Terms or which would otherwise be considered offensive to other visitors, users and/or members.`,
    },
    {
      type: 'body',
      value: `Software Development LLC herein reserves the right to access, preserve and/or disclose member account information and/or content if it is requested to do so by law or in good faith belief that any such action is deemed reasonably necessary for:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `compliance with any legal process;`,
          },
          {
            value: `enforcement of the Terms;`,
          },
          {
            value: `responding to any claim that therein contained content is in violation of the rights of any third party;`,
          },
          {
            value: `responding to requests for customer service; or`,
          },
          {
            value: `protecting the rights, property or the personal safety of Software Development LLC, its visitors, users and members, including the general public.`,
          },
        ],
      },
    },
    {
      type: 'body',
      value: `Software Development LLC herein reserves the right to include the use of security components that may permit digital information or material to be protected, and that such use of information and/or material is subject to usage guidelines and regulations established by Software Development LLC or any other content providers supplying content services to Software Development LLC. You are hereby prohibited from making any attempt to override or circumvent any of the embedded usage rules in our Services. Furthermore, unauthorized reproduction, publication, distribution, or exhibition of any information or materials supplied by our Services, despite whether done so in whole or in part, is expressly prohibited.`,
    },
    {
      type: 'heading',
      value: `SECTION 4 - GLOBAL USE; EXPORT/IMPORT COMPLIANCE`,
    },
    {
      type: 'body',
      value: `Due to the global nature of the internet, through the use of our network you hereby agree to comply with all local rules relating to online conduct and that which is considered acceptable content. Uploading, posting and/or transferring of software, technology and other technical data may be subject to the export and import laws of the United States and possibly other countries. Through the use of our network, you thus agree to comply with all applicable export and import laws, statutes and regulations, including, but not limited to, the Export Administration Regulations (http://www.access.gpo.gov/bis/ear/ear_data.html), as well as the sanctions control program of the United States (http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx). Furthermore, you state and pledge that you:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `are not on the list of prohibited individuals which may be identified on any government export exclusion report (http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm) nor a member of any other government which may be part of an export-prohibited country identified in applicable export and import laws and regulations;`,
          },
          {
            value: `agree not to transfer any software, technology or any other technical data through the use of our network Services to any export-prohibited country;`,
          },
          {
            value: `agree not to use our website network Services for any military, nuclear, missile, chemical or biological weaponry end uses that would be a violation of the U.S. export laws; and`,
          },
          {
            value: `agree not to post, transfer nor upload any software, technology or any other technical data which would be in violation of the U.S. or other applicable export and/or import laws.`,
          },
        ],
      },
    },
    {
      type: 'heading',
      value: `SECTION 5 - SUBMITTED CONTENT`,
    },
    {
      type: 'body',
      value: `Software Development LLC shall not lay claim to ownership of any content submitted by any visitor, member, or user, nor make such content available for inclusion on our website Services. Therefore, you hereby grant and allow for Software Development LLC the below listed worldwide, royalty-free and non-exclusive licenses, as applicable:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `The content submitted or made available for inclusion on the publicly accessible areas of Software Development LLC's Sites, the license provided to permit to use, distribute, reproduce, modify, adapt, publicly perform and/or publicly display said Content on our network Services is for the sole purpose of providing and promoting the specific area to which this content was placed and/or made available for viewing. This license shall be available so long as you are a member of Software Development LLC's sites, and shall terminate at such time when you elect to discontinue your membership.`,
          },
          {
            value: `Photos, audio, video and/or graphics submitted or made available for inclusion on the publicly accessible areas of Software Development LLC's sites, the license provided to permit to use, distribute, reproduce, modify, adapt, publicly perform and/or publicly display said Content on our network Services are for the sole purpose of providing and promoting the specific area in which this content was placed and/or made available for viewing. This license shall be available so long as you are a member of Software Development LLC's sites and shall terminate at such time when you elect to discontinue your membership.`,
          },
          {
            value: `For any other content submitted or made available for inclusion on the publicly accessible areas of Software Development LLC's sites, the continuous, binding and completely sub-licensable license which is meant to permit to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform and/or publicly display said content, whether in whole or in part, and the incorporation of any such Content into other works in any arrangement or medium current used or later developed.`,
          },
        ],
      },
    },
    {
      type: 'body',
      value: `Those areas which may be deemed "publicly accessible" areas of Software Development LLC's Sites are those such areas of our network properties which are meant to be available to the general public, and which would include message boards and groups that are openly available to both users and members. However, those areas which are not open to the public, and thus available to members only, would include our mail system and instant messaging.`,
    },
    {
      type: 'body',
      value: `CONTRIBUTIONS TO COMPANY WEBSITE`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `your contributions do not contain any type of confidential or proprietary information;`,
          },
          {
            value: `Software Dev shall not be liable or under any obligation to ensure or maintain confidentiality, expressed or implied, related to any Contributions;`,
          },
          {
            value: `Software Dev shall be entitled to make use of and/or disclose any such Contributions in any such manner as they may see fit;`,
          },
          {
            value: `the contributor's Contributions shall automatically become the sole property of Software Dev; and`,
          },
          {
            value: `Software Dev is under no obligation to either compensate or provide any form of reimbursement in any manner or nature.`,
          },
        ],
      },
    },
    {
      type: 'heading',
      value: `SECTION 6 - INDEMNITY`,
    },
    {
      type: 'body',
      value: `All users and/or members agree to insure and holdSoftware Development LLC, our subsidiaries, affiliates, agents, employees, officers, partners and/or licensors blameless or not liable for any claim or demand, which may include, but is not limited to, reasonable attorney fees made by any third party which may arise from any content a member or user of our Site may submit, post, modify, transmit or otherwise make available through our Services, the use of Software Dev Services or your connection with these Services, your violations of the Terms of Service and/or your violation of any such rights of another person.`,
    },
    {
      type: 'heading',
      value: `SECTION 7 - COMMERCIAL REUSE OF SERVICES`,
    },
    {
      type: 'body',
      value: `The member or user herein agrees not to replicate, duplicate, copy, trade, sell, resell nor exploit for any commercial reason any part, use of, or access to Software Dev's sites.`,
    },
    {
      type: 'heading',
      value: `SECTION 8 - MODIFICATIONS`,
    },
    {
      type: 'body',
      value: `Software Development LLC reserves the right at any time it may deem fit, to modify, alter and or discontinue, whether temporarily or permanently, our service, or any part thereof, with or without prior notice. In addition, we shall not be held liable to you or to any third party for any such alteration, modification, suspension and/or discontinuance of our Services, or any part thereof.`,
    },
    {
      type: 'heading',
      value: `SECTION 9 - TERMINATION`,
    },
    {
      type: 'body',
      value: `As a member of https://app.gobodhi.com, you may cancel or terminate your account, associated email address and/or access to our Services by submitting a cancellation or termination request to.`,
    },
    {
      type: 'body',
      value: `As a member, you agree that Software Development LLC may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your account, any email associated with your account, and access to any of our Services. The cause for such termination, discontinuance, suspension and/or limitation of access shall include, but is not limited to:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `any breach or violation of our Terms or any other incorporated agreement, regulation and/or guideline;`,
          },
          {
            value: `by way of requests from law enforcement or any other governmental agencies;`,
          },
          {
            value: `the discontinuance, alteration and/or material modification to our Services, or any part thereof;`,
          },
          {
            value: `unexpected technical or security issues and/or problems;`,
          },
          {
            value: `any extended periods of inactivity;`,
          },
          {
            value: `any engagement by you in any fraudulent or illegal activities; and/or`,
          },
          {
            value: `the nonpayment of any associated fees that may be owed by you in connection with your https://app.gobodhi.com account Services.`,
          },
        ],
      },
    },
    {
      type: 'body',
      value: `Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or limitations of access for cause shall be made at our sole discretion and that we shall not be liable to you or any other third party with regards to the termination of your account, associated email address and/or access to any of our Services.`,
    },
    {
      type: 'body',
      value: `The termination of your account with https://app.gobodhi.com shall include any and/or all of the following:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `the removal of any access to all or part of the Services offered within https://app.gobodhi.com;`,
          },
          {
            value: `the deletion of your password and any and all related information, files, and any such content that may be associated with or inside your account, or any part thereof; and`,
          },
          {
            value: `the barring of any further use of all or part of our Services.`,
          },
        ],
      },
    },
    {
      type: 'heading',
      value: `SECTION 10 - LINKS`,
    },
    {
      type: 'body',
      value: `Either Software Development LLC or any third parties may provide links to other websites and/or resources. Thus, you acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and as such, we do not endorse nor are we responsible or liable for any content, products, advertising or any other materials, on or available from such third-party sites or resources. Furthermore, you acknowledge and agree that Software Development LLC shall not be responsible or liable, directly or indirectly, for any such damage or loss which may be a result of, caused or allegedly to be caused by or in connection with the use of or the reliance on any such content, goods or Services made available on or through any such site or resource.`,
    },
    {
      type: 'heading',
      value: `SECTION 11 - PROPRIETARY RIGHTS`,
    },
    {
      type: 'body',
      value: `You do hereby acknowledge and agree that Software Development LLC's Services and any essential software that may be used in connection with our Services ("Software") shall contain proprietary and confidential material that is protected by applicable intellectual property rights and other laws. Furthermore, you herein acknowledge and agree that any Content which may be contained in any advertisements or information presented by and through our Services or by advertisers is protected by copyrights, trademarks, patents or other proprietary rights and laws. Therefore, except for that which is expressly permitted by applicable law or as authorized by Software Development LLC or such applicable licensor, you agree not to alter, modify, lease, rent, loan, sell, distribute, transmit, broadcast, publicly perform and/or created any plagiaristic works which are based on Software Development LLC Services (e.g. Content or Software), in whole or part.`,
    },
    {
      type: 'body',
      value: `Software Development LLC hereby grants you a personal, non-transferable and non-exclusive right and/or license to make use of the object code or our Software on a single computer, as long as you do not, and shall not, allow any third party to duplicate, alter, modify, create or plagiarize work from, reverse engineer, reverse assemble or otherwise make an attempt to locate or discern any source code, sell, assign, sublicense, grant a security interest in and/or otherwise transfer any such right in the Software. Furthermore, you do herein agree not to alter or change the Software in any manner, nature or form, and as such, not to use any modified versions of the Software, including and without limitation, for the purpose of obtaining unauthorized access to our Services. Lastly, you also agree not to access or attempt to access our Services through any means other than through the interface which is provided by Software Development LLC for use in accessing our Services.`,
    },
    {
      type: 'heading',
      value: `SECTION 12 - WARRANTY DISCLAIMERS`,
    },
    {
      type: 'body',
      value: `YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `THE USE OF SOFTWARE DEVELOPMENT LLC SERVICES AND SOFTWARE ARE AT THE SOLE RISK BY YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN "AS IS" AND/OR "AS AVAILABLE" BASIS. SOFTWARE DEVELOPMENT LLC AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.`,
          },
          {
            value: `SOFTWARE DEVELOPMENT LLC AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i) SOFTWARE DEVELOPMENT LLC SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) SOFTWARE DEVELOPMENT LLC SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE SOFTWARE DEVELOPMENT LLC SERVICES OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE CORRECTED.`,
          },
          {
            value: `ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF SOFTWARE DEVELOPMENT LLC SERVICES OR SOFTWARE SHALL BE ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL.`,
          },
          {
            value: `NO ADVICE AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE OBTAINED BY YOU FROM SOFTWARE DEVELOPMENT LLC OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.`,
          },
          {
            value: `A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME DEGREE OF EPILEPTIC SEIZURE WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS THAT MAY BE CONTAINED ON A COMPUTER SCREEN OR WHILE USING OUR SERVICES. CERTAIN CONDITIONS MAY INDUCE A PREVIOUSLY UNKNOWN CONDITION OR UNDETECTED EPILEPTIC SYMPTOM IN USERS WHO HAVE SHOWN NO HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD YOU, ANYONE YOU KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC CONDITION, PLEASE CONSULT A PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES: DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.`,
          },
        ],
      },
    },
    {
      type: 'heading',
      value: `SECTION 13 - LIMITATION OF LIABILITY
      `,
    },
    {
      type: 'body',
      value: `YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT SOFTWARE DEVELOPMENT LLC AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `THE USE OR INABILITY TO USE OUR SERVICE;`,
          },
          {
            value: `THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;`,
          },
          {
            value: `UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA;`,
          },
          {
            value: `STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR SERVICE;`,
          },
          {
            value: `AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.`,
          },
        ],
      },
    },
    {
      type: 'heading',
      value: `SECTION 14 - RELEASE`,
    },
    {
      type: 'body',
      value: `In the event you have a dispute, you agree to release Software Development LLC(and its officers, directors, employees, agents, parent subsidiaries, affiliates, co-branders, partners and any other third parties) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising out of or in any way connected to such dispute.`,
    },
    {
      type: 'heading',
      value: `SECTION 15 - NOTICE`,
    },
    {
      type: 'body',
      value: `Software Development LLC may furnish you with notices, including those with regards to any changes to the Terms, including but not limited to email, regular mail, MMS or SMS, text messaging, postings on our website Services, or other reasonable means currently known or any which may be herein after developed. Any such notices may not be received if you violate any aspects of the Terms by accessing our Services in an unauthorized manner. Your acceptance of this Agreement constitutes your agreement that you are deemed to have received any and all notices that would have been delivered had you accessed our Services in an authorized manner.`,
    },
    {
      type: 'heading',
      value: `SECTION 16 - INTELLECTUAL PROPERTY RIGHTS`,
    },
    {
      type: 'body',
      value: `You herein acknowledge, understand and agree that all of the Software Development LLCtrademarks, copyright, trade name, service marks, and other Software Development LLClogos and any brand features, and/or product and service names are trademarks and as such, are and shall remain the property of Software Development LLC. You herein agree not to display and/or use in any manner the Software Development LLClogo or marks without obtaining Software Development LLC's prior written consent.`,
    },
    {
      type: 'body',
      value: `Software Development LLC will always respect the intellectual property of others, and we ask that all of our users do the same. With regards to appropriate circumstances and at its sole discretion, Software Development LLC may disable and/or terminate the accounts of any user who violates our Terms and/or infringes the rights of others. If you feel that your work has been duplicated in such a way that would constitute copyright infringement, or if you believe your intellectual property rights have been otherwise violated, you should provide to us the following information:`,
    },
    {
      type: 'alphabetical-list',
      value: {
        items: [
          {
            value: `The electronic or the physical signature of the individual that is authorized on behalf of the owner of the copyright or other intellectual property interest;`,
          },
          {
            value: `A description of the copyrighted work or other intellectual property that you believe has been infringed upon;`,
          },
          {
            value: `A description of the location of the site which you allege has been infringing upon your work;`,
          },
          {
            value: `Your physical address, telephone number, and email address;`,
          },
          {
            value: `A statement, in which you state that the alleged and disputed use of your work is not authorized by the copyright owner, its agents or the law;`,
          },
          {
            value: `And finally, a statement, made under penalty of perjury, that the aforementioned information in your notice is truthful and accurate, and that you are the copyright or intellectual property owner, representative or agent authorized to act on the copyright or intellectual property owner's behalf.`,
          },
        ],
      },
    },
    {
      type: 'body',
      value: `The Software Development LLC agent for notice of claims of copyright or other intellectual property infringement can be contacted as follows:`,
    },
    {
      type: 'body',
      value: `Mailing Address: Software Development LLC`,
    },
    {
      type: 'body',
      value: `Attn: Copyright Agent`,
    },
    {
      type: 'body',
      value: `6365 NW 6th Way Ste 170 Fort Lauderdale, Florida 33309`,
    },
    {
      type: 'body',
      value: `Telephone: 800-467-0717`,
    },
    {
      type: 'body',
      value: `Email: licensingsupport@gobodhi.com`,
    },
    {
      type: 'heading',
      value: `SECTION 17 - ENTIRE AGREEMENT`,
    },
    {
      type: 'body',
      value: `This Agreement constitutes the entire agreement between you and Software Development LLCand shall govern the use of our Services, superseding any prior version of this Agreement between you and us with respect to Software Development LLC Services. You may also be subject to additional terms and conditions that may apply when you use or purchase certain other Software Development LLC Services, affiliate Services, third-party content or third-party software.`,
    },
    {
      type: 'heading',
      value: `SECTION 18 - CHOICE OF LAW AND FORUM`,
    },
    {
      type: 'body',
      value: `It is at the mutual agreement of both you and Software Development LLC with regard to the Agreement that the relationship between the parties shall be governed by the laws of the state of Florida without regard to its conflict of law provisions and that any and all claims, causes of action and/or disputes, arising out of or relating to theAgreement, or the relationship between you and Software Development LLC, shall be filed within the courts having jurisdiction within the County of Broward County, Florida or the U.S. District Court located in said state. You and Software Development LLC agree to submit to the jurisdiction of the courts as previously mentioned, and agree to waive any and all objections to the exercise of jurisdiction over the parties by such courts and to venue in such courts.`,
    },
    {
      type: 'heading',
      value: `SECTION 19 - WAIVER AND SEVERABILITY OF TERMS`,
    },
    {
      type: 'body',
      value: `At any time, should Software Development LLC fail to exercise or enforce any right or provision of the Agreement, such failure shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Agreement remain in full force and effect.`,
    },
    {
      type: 'heading',
      value: `SECTION 20 - NO RIGHT OF SURVIVORSHIP NON-TRANSFERABILITY`,
    },
    {
      type: 'body',
      value: `You acknowledge, understand and agree that your account is non-transferable and any rights to your ID and/or contents within your account shall terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated and all contents therein permanently deleted.`,
    },
    {
      type: 'heading',
      value: `SECTION 21 - STATUTE OF LIMITATIONS`,
    },
    {
      type: 'body',
      value: `You acknowledge, understand and agree that regardless of any statute or law to the contrary, any claim or action arising out of or related to the use of our Services or the Agreement must be filed within 5 year(s) after said claim or cause of action arose or shall be forever barred.`,
    },
    {
      type: 'heading',
      value: `SECTION 22 - VIOLATIONS`,
    },
    {
      type: 'body',
      value: `Please report any and all violations of this Agreement to Software Development LLC as follows:`,
    },
    {
      type: 'body',
      value: `Mailing Address: Software Development LLC 6365 NW 6th Way Ste 170 Fort Lauderdale, Florida 33309`,
    },
    {
      type: 'body',
      value: `Telephone: 800-467-0717`,
    },
    {
      type: 'body',
      value: `Email: licensingsupport@gobodhi.com`,
    },
    {
      type: 'heading',
      value: `SECTION 23 - GOVERNMENT REQUESTS`,
    },
    {
      type: 'body',
      value: `In order to cooperate with governmental requests, subpoenas or court orders, to protect our systems, or to ensure the integrity and operation of our business and systems, we may access and disclose any information we consider necessary or appropriate, including and without limitation, your information, IP address, and usage history. Our right to disclose any such information is governed by the terms of our Privacy Policy.`,
    },
    {
      type: 'heading',
      value: `SECTION 24 - FOREIGN ACCESS OF SITE`,
    },
    {
      type: 'body',
      value: `The Site is controlled, operated and administered by Software Dev from our offices within the USA. If you access the Site from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use Software Dev's content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations.`,
    },
    {
      type: 'heading',
      value: `SECTION 25 - ERRORS, INACCURACIES AND OMISSIONS`,
    },
    {
      type: 'body',
      value: `Occasionally there may be information on our Site that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Site or on any related Site is inaccurate at any time without prior notice (including after you have submitted your order).`,
    },
    {
      type: 'body',
      value: `We undertake no obligation to update, amend or clarify information on the Site or on any related Site, including without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Site or on any related Site, should be taken to indicate that all information on the Site or on any related Site has been modified or updated.`,
    },
    {
      type: 'heading',
      value: `SECTION 26 - PRIVACY POLICY`,
    },
    {
      type: 'body',
      value: `Every member's registration data and various other personal information are strictly protected by the Software Development LLC Online Privacy Policy. As a member, you herein consent to the collection and use of the information provided, including the transfer of information within the United States and/or other countries for storage, processing or use by Software Development LLC and/or our subsidiaries and affiliates.`,
    },
  ],
};

// OLD
export const homePageContent = {
  sectionIntro: {
    banner_01: {
      title: () => {
        return <span className="title">Simplify your building operation</span>;
      },
      mainParagraph: () => {
        return (
          <span className="info">
            Intuitive, comprehensive management of multiple guest units in hotels, resorts and
            condominiums, with fast, effective monitoring and control.
          </span>
        );
      },
    },
    banner_02: {
      title: () => {
        return <span className="title">Match made in the cloud</span>;
      },
      mainParagraph: () => {
        return <span className="info">Enterprise hardware paired with comprehensive control.</span>;
      },
    },
    banner_03: {
      title: () => {
        return (
          <span className="title">
            Limited services with <br />
            unlimited control
          </span>
        );
      },
      mainParagraph: () => {
        return <span className="info">Premium guest room lighting that is budget friendly.</span>;
      },
    },
    banner_04: {
      title: () => {
        return <span className="title">Connecting all the dots</span>;
      },
      mainParagraph: () => {
        return (
          <span className="info">
            Introducing the Bodhi Bridge, reliability at a fraction of the cost.
          </span>
        );
      },
    },
  },
  sectionMeet: {
    title: `Meet bodhi`,
    mainParagraph: `
      Bodhi is a cloud based building operation platform bringing alerts, monitoring, and analytics of all devices that are associated with it. What makes Bodhi special is that it also acts as control system, and can directly automate and schedule all of the devices that it is monitoring.
    `,
  },
  sectionFeatures: {
    title: `Core Features`,
  },
  sectionProjects: {
    title: `Projects`,
  },
  sectionSolutions: {
    title: `Industry-leading solutions`,
    mainParagraph: `
      Bodhi integrates deeply with enterprise partners such as Crestron and Lutron and brings a new level of service to the hospitality, commercial, and residential industries.
    `,
  },
};

export const featureBank = {
  dashboard: {
    key: 'dashboard',
    name: 'Dashboard',
    description:
      'Designed for real people such as property managers, homeowners and management staff, not just engineers looking at a spreadsheet',
    bulletPoints: [
      'Quickly see if a device has an issue with real time alerting',
      'High level overviews that can drill down to individual devices',
      'Not only used for monitoring, but also for setting up alerts and automation',
    ],
  },
  analytics: {
    key: 'analytics',
    name: 'Analytics',
    description:
      'Charts and graphs showing utilization of spaces, trends of heating and cooling and logging of lighting and audio levels',
    additionalDescription: [
      'Not just visual reports, our analytics have built in intelligence that triggers alerts when devices start operating outside of normal tolerances.',
    ],
  },
  scheduling: {
    key: 'scheduling',
    name: 'Scheduling',
    description:
      'Real time cloud based calendar that trigger automation such as recalling the perfect dinner mood in the restaurant, or setting the stage for a rooftop hotel pool party on a Sunday afternoon.',
  },
  security: {
    key: 'security',
    name: 'Security',
    description: 'Granular control levels for access, alerts, and configuration.',
    bulletPoints: ['Built on Microsoft Azure'],
  },
  integrations: {
    key: 'integrations',
    name: 'Integrations',
    description:
      'Enhance your guest room experience by providing Alexa Voice Control for their in-room devices or request house keeping services.',
    additionalDescription: [
      'Also, tie in your existing property management platform and instantly set your guest rooms to eco-mode when vacant.',
    ],
  },
};

export const projectBank = {
  // albany: {
  //   key: 'albany',
  //   name: 'Albany Bahamas',
  //   description:
  //     'Real-time management and monitoring across all Albany Marina Residences including in-unit Crestron Lighting and Climate controls and scheduled pool lighting.',
  //   imageSrc: require('../images/projects/project_albany.png'),
  // },
  artovation: {
    key: 'artovation',
    name: 'Art Ovation',
    description:
      'Monitoring and scheduling of Crestron lighting and music throughout the common areas with instant customization.',
    imageSrc: require('../images/projects/project_artovation.png'),
  },
  daytona: {
    key: 'daytona',
    name: 'The Daytona',
    description:
      'Management, monitoring and scheduling of event area, restaurant, lobby and common area Crestron Lighting Control and QSC QSys Music distribution.',
    imageSrc: require('../images/projects/project_daytona.png'),
  },
  nexus: {
    key: 'nexus',
    name: 'the Nexus Club',
    description:
      'Management, monitoring and scheduling of Crestron Climate, Lighting, Music and Motorized Window Treatments member’s lounges, restaurant, bar, event space and private meeting rooms.',
    imageSrc: require('../images/projects/project_nexus.png'),
  },
  tsi: {
    key: 'tsi',
    name: 'Think Simple',
    description:
      'Management, monitoring and scheduling of Crestron Climate, Lighting, Music and Motorized Window Treatments in offices, corridors and break area.',
    imageSrc: require('../images/projects/project_tsi.png'),
  },
  airbnb: {
    key: 'airbnb',
    name: 'Airbnb Home',
    description:
      'Monitoring and alerts of Z-wave sensors that detect activity and humidity to prevent tenant negligence.',
    imageSrc: require('../images/projects/project_airbnb.png'),
  },
};
export const defaultProject = projectBank.artovation;

export const partnerBank = {
  crestron: {
    key: 'crestron',
    name: 'Crestron',
    description: `
      Crestron engineers high-tech solutions for every market. From customized luxury smart home automation to scalable out of the box collaborative tools that will guarantee reliable, easy to use solutions. 
    `,
    link: '/crestron',
  },
  lutron: {
    key: 'lutron',
    name: 'Lutron',
    description: `
      Lutron designs and manufactures more than 15,000 energy-saving products, from dimmers for the home to light management systems for  buildings. Lutron is committed to innovation.
    `,
    link: '/lutron',
  },
  zwave: {
    key: 'zwave',
    name: 'Z-Wave',
    description: `
      Z-Wave products are designed to work together. Whether you are away for business or on holiday, or just simply at work down the street, you have full home control and access from any location.
    `,
    link: '/zwave',
  },
};

export const formContent = {
  '0a': {
    header: 'Request a demo',
    description: `
      Select the project type you work most closely with.
    `,
  },
  '1a': {
    header: 'Which best describes your role?',
    // description: 'description'
  },
  '2a': {
    header: 'We service worldwide',
    description: `
      Where are you located?
    `,
  },
  '3a': {
    header: 'Contact Information',
    description: `
      How do we reach you?
    `,
  },
  '9a': {
    header: 'Thank you!',
    description: `
      We’ve received your information, and we’ll be in touch soon!
    `,
  },
};
