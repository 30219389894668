// all icons are from the font awesome library
export const paths = {
  'angle-left-solid':
    'M7.50174 12.7992l6.37496 6.375c.4407.4406 1.1532.4406 1.5891 0l1.0594-1.0594c.4406-.4406.4406-1.1531 0-1.589l-4.5188-4.5188 4.5188-4.51872c.4406-.44062.4406-1.15312 0-1.58906l-1.0547-1.06875c-.4406-.44063-1.1531-.44063-1.5891 0L7.50643 11.2055c-.44531.4406-.44531 1.1531-.00469 1.5937z',
  'angle-right-solid':
    'M16.5258 12.7992l-6.375 6.375c-.44064.4406-1.15314.4406-1.58908 0l-1.05938-1.0594c-.44062-.4406-.44062-1.1531 0-1.589l4.51876-4.5188-4.51876-4.51872c-.44062-.44062-.44062-1.15312 0-1.58906l1.05469-1.06875c.44063-.44063 1.15313-.44063 1.58907 0l6.375 6.37503c.4453.4406.4453 1.1531.0047 1.5937z',
  'bars-solid':
    'M2.25 6.1875h19.5c.4142 0 .75-.33577.75-.75v-1.875c0-.41423-.3358-.75-.75-.75H2.25c-.41423 0-.75.33577-.75.75v1.875c0 .41423.33577.75.75.75zm0 7.5h19.5c.4142 0 .75-.3358.75-.75v-1.875c0-.4142-.3358-.75-.75-.75H2.25c-.41423 0-.75.3358-.75.75v1.875c0 .4142.33577.75.75.75zm0 7.5h19.5c.4142 0 .75-.3358.75-.75v-1.875c0-.4142-.3358-.75-.75-.75H2.25c-.41423 0-.75.3358-.75.75v1.875c0 .4142.33577.75.75.75z',
  'caret-down-solid':
    'M5.95335 8.0625H18.0143c.8344 0 1.2516 1.00781.6609 1.59844l-6.0281 6.03276c-.3656.3657-.9609.3657-1.3266 0L5.29241 9.66094c-.59062-.59063-.17343-1.59844.66094-1.59844z',
  'check-solid':
    'M8.15146 20.597l-7.800003-7.8c-.468609-.4686-.468609-1.2284 0-1.697L2.04847 9.40291c.46861-.46866 1.22846-.46866 1.69707 0l5.25445 5.25439L20.2544 3.40291c.4687-.46861 1.2285-.46861 1.6971 0l1.697 1.69706c.4686.46861.4686 1.22841 0 1.69706L9.84852 20.5971c-.46865.4686-1.22845.4686-1.69706-.0001z',
  'linkedin-brands':
    'M6.20062 22.4995H1.84688V8.47922h4.35374V22.4995zM4.02141 6.56672C2.62922 6.56672 1.5 5.41359 1.5 4.02141c0-.66872.26565-1.31005.7385-1.78291C2.71136 1.76565 3.35269 1.5 4.02141 1.5c.66871 0 1.31004.26565 1.7829.7385.47285.47286.7385 1.11419.7385 1.78291 0 1.39218-1.12968 2.54531-2.5214 2.54531zM22.4953 22.4995h-4.3444v-6.825c0-1.6265-.0328-3.7125-2.2636-3.7125-2.2636 0-2.6104 1.7672-2.6104 3.5953v6.9422H8.92781V8.47922h4.17559v1.91248h.061c.5812-1.10154 2.0011-2.26404 4.1194-2.26404 4.4062 0 5.2162 2.90154 5.2162 6.67034v7.7015h-.0047z',
  'long-arrow-alt-right-solid':
    'M16.216 10.13H2.0625c-.31064 0-.5625.2518-.5625.5625v2.625c0 .3106.25186.5625.5625.5625H16.216v2.159c0 1.0023 1.2117 1.5042 1.9205.7955l4.034-4.034c.4394-.4394.4394-1.1517 0-1.591l-4.034-4.034c-.7087-.7087-1.9205-.20676-1.9205.79552V10.13z',
  'map-marker-alt-solid':
    'M11.0751 23.5158C4.26422 13.6421 3 12.6287 3 9c0-4.97058 4.02942-9 9-9 4.9706 0 9 4.02942 9 9 0 3.6287-1.2642 4.6421-8.0751 14.5158-.4469.6456-1.4029.6456-1.8498 0zM12 12.75c2.0711 0 3.75-1.6789 3.75-3.75 0-2.07108-1.6789-3.75-3.75-3.75-2.07108 0-3.75 1.67892-3.75 3.75 0 2.0711 1.67892 3.75 3.75 3.75z',
  'play-solid':
    'M21.3937 10.0626L4.89375.307872C3.55313-.484315 1.5.284435 1.5 2.24381V21.7485c0 1.7578 1.90781 2.8172 3.39375 1.9359l16.49995-9.75c1.4719-.8672 1.4766-3.0047 0-3.8718z',
  'plus-solid':
    'M21 9.75h-6.75V3c0-.82828-.6717-1.5-1.5-1.5h-1.5c-.8283 0-1.5.67172-1.5 1.5v6.75H3c-.82828 0-1.5.6717-1.5 1.5v1.5c0 .8283.67172 1.5 1.5 1.5h6.75V21c0 .8283.6717 1.5 1.5 1.5h1.5c.8283 0 1.5-.6717 1.5-1.5v-6.75H21c.8283 0 1.5-.6717 1.5-1.5v-1.5c0-.8283-.6717-1.5-1.5-1.5z',
  'times-solid':
    'M15.1275 12l4.6908-4.69078c.5756-.57563.5756-1.50891 0-2.085l-1.0425-1.0425c-.5756-.57563-1.5089-.57563-2.085 0L12 8.8725 7.30922 4.18172c-.57563-.57563-1.50891-.57563-2.085 0l-1.0425 1.0425c-.57563.57562-.57563 1.5089 0 2.085L8.8725 12l-4.69078 4.6908c-.57563.5756-.57563 1.5089 0 2.085l1.0425 1.0425c.57562.5756 1.50937.5756 2.085 0L12 15.1275l4.6908 4.6908c.5756.5756 1.5094.5756 2.085 0l1.0425-1.0425c.5756-.5756.5756-1.5089 0-2.085L15.1275 12z',
};
