import React, {Fragment} from 'react';
import './paragraph.css';

export const Paragraph = props => {
  // PROPS
  const {
    content,
    children,
    display,
    lineHeight = '1.2em', 
    color = '#000000',
    size = 1,
    textAlign = 'left',
    styleProps = {},
  } = props;

  // CLASSNAME
  let className = `paragraph paragraph--size-${size}`;

  // STYLE
  let style = {
    color,
    display,
    lineHeight,
    textAlign,
    ...styleProps,
  };

  // RENDER
  return (
    <p className={className} style={{...style}}>
      {children || content}
    </p>
  );
};
