import React from 'react';
import './modal.css';
import {Transition} from 'react-transition-group';

export const Modal = props => {
  // PROPS
  const {children, isVisible = true, backgroundOnClick = () => {}} = props;

  // EVENT HANDLERS
  const onModalBackgroundClick = e => {
    console.log(e);
    backgroundOnClick();
  };

  const modalTransitionStyles = {
    entering: {opacity: 0},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacity: 0},
  };

  // RENDER
  return (
    <Transition in={isVisible} timeout={500} unmountOnExit>
      {transitionState => (
        <div
          className="modal"
          style={{
            ...modalTransitionStyles[transitionState],
          }}
        >
          <div className="modal-background" onClick={onModalBackgroundClick} />
          <div className="modal-content">{children}</div>
        </div>
      )}
    </Transition>
  );
};
