import React, {Fragment} from 'react';
import './heading.css';

export const Heading = props => {
  // PROPS
  const {
    children,
    color = '#000000',
    content,
    display = 'block',
    importance = 1,
    lineHeight = '1.2em',
    size = 1,
    textAlign = 'left',
    textTransform = 'unset',
    styleProps = {},
  } = props;

  // CLASSNAME
  let className = `heading heading--size-${size}`;

  // STYLE
  let style = {
    color,
    display,
    lineHeight,
    textAlign,
    textTransform,
    ...styleProps,
  };

  // RENDER
  return (
    <Fragment>
      {/* H1 */}
      {importance === 1 && (
        <h1 className={className} style={{...style}}>
          {children || content}
        </h1>
      )}
      {/* H2 */}
      {importance === 2 && (
        <h2 className={className} style={{...style}}>
          {children || content}
        </h2>
      )}
      {/* H3 */}
      {importance === 3 && (
        <h3 className={className} style={{...style}}>
          {children || content}
        </h3>
      )}
      {/* H4 */}
      {importance === 4 && (
        <h4 className={className} style={{...style}}>
          {children || content}
        </h4>
      )}
      {/* H5 */}
      {importance === 5 && (
        <h5 className={className} style={{...style}}>
          {children || content}
        </h5>
      )}
      {/* H6 */}
      {importance === 6 && (
        <h6 className={className} style={{...style}}>
          {children || content}
        </h6>
      )}
    </Fragment>
  );
};
