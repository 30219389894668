import React from 'react';
import {paths} from './icon-paths';

export const Icon = props => {
  // props
  const {size = 24, color = '#000000', name = 'bars-solid'} = props;

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d={paths[name]} fill={color} />
    </svg>
  );
};
