import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import {Carousel} from 'react-responsive-carousel';
import {request as requestContent} from '../../../helpers/content';
import locationData from '../../../helpers/locationData.json';
import {createRef} from '../../../helpers/firebase';
import {COLLECTION_LEADS} from '../../../helpers/constants';
import {salesforceConfiguration} from '../../../config/saleforce';
import {Heading, Paragraph, Icon} from '../../ui';
import './request.css';

export const Request = () => {
  // STATE
  const [formData, setFormData] = useState({
    projectType: undefined,
    profession: undefined,
    locationData: {
      countryCode: undefined,
      countryName: undefined,
      stateProvinceCode: undefined,
      stateProvinceName: undefined,
      sharedLocation: false,
    },
    contactData: {
      company: undefined,
      name: undefined,
      email: undefined,
      phoneNumber: undefined,
      preferredContactMethod: undefined,
      preferredContactMethodName: undefined,
    },
  });
  const [triggerCarouselResize, setTriggerCarouselResize] = useState({});
  const [selectedItem, setSelectedItem] = useState(0);
  const [shareYourLocationButtonIsLoading, setShareYourLocationButtonIsLoading] = useState(false);
  const [shareYourLocationButtonIsDisabled, setShareYourLocationButtonIsDisabled] = useState(false);
  const [shareYourLocationButtonNegativeMessage, setShareYourLocationButtonNegativeMessage] =
    useState(undefined);
  const [shareYourLocationButtonPositiveMessage, setShareYourLocationButtonPositiveMessage] =
    useState(undefined);
  const [backButtonIsVisible, setBackButtonIsVisible] = useState(false);
  const [nextButtonIsVisible, setNextButtonIsVisible] = useState(false);
  const [nextButtonIsDisabled, setNextButtonIsDisabled] = useState(false);
  const [submitButtonIsVisible, setSubmitButtonIsVisible] = useState(false);
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false);

  // REFS
  const salesforceSubmitButtonRef = useRef(null);

  // USE EFFECT
  // CHECKS WHEN TO SHOW BACK, NEXT, AND SUBMIT BUTTONS
  useEffect(() => {
    // PROJECT TYPE SLIDE
    if (selectedItem === 0) {
      setBackButtonIsVisible(false);
      setNextButtonIsVisible(true);
      setSubmitButtonIsVisible(false);
      if (formData.projectType) {
        setNextButtonIsDisabled(false);
      } else {
        setNextButtonIsDisabled(true);
      }
    }
    // PROFESSION SLIDE
    if (selectedItem === 1) {
      setBackButtonIsVisible(true);
      setNextButtonIsVisible(true);
      setSubmitButtonIsVisible(false);
      if (formData.profession) {
        setNextButtonIsDisabled(false);
      }
    }
    // LOCATION SLIDE
    if (selectedItem === 2) {
      setBackButtonIsVisible(true);
      setNextButtonIsVisible(true);
      setSubmitButtonIsVisible(false);
      if (
        formData.locationData.countryCode &&
        formData.locationData.countryName &&
        formData.locationData.stateProvinceCode &&
        formData.locationData.stateProvinceName
      ) {
        setNextButtonIsDisabled(false);
      }
    }
    // CONTACT SLIDE
    if (selectedItem === 3) {
      setBackButtonIsVisible(true);
      setNextButtonIsVisible(false);
      setSubmitButtonIsVisible(true);
      if (
        formData.contactData.name &&
        formData.contactData.phoneNumber &&
        formData.contactData.company &&
        formData.contactData.email &&
        formData.contactData.preferredContactMethod
      ) {
        setSubmitButtonIsDisabled(false);
      } else {
        setSubmitButtonIsDisabled(true);
      }
    }
    // CONFIRMATION SLIDE
    if (selectedItem === 4) {
      setBackButtonIsVisible(false);
      setNextButtonIsVisible(false);
      setSubmitButtonIsVisible(false);
    }
  }, [formData, selectedItem]);

  // EVENT HANDLERS
  const onProjectTypeClick = value => {
    updateFormData('projectType', value);

    // DEBUGGING
    console.log('[debug] onProjectTypeClick called');
    console.log(`[debug] onProjectTypeClick value: ${value}`);
  };

  const onProfessionClick = value => {
    updateFormData('profession', value);

    // DEBUGGING
    console.log('[debug] onProjectTypeClick called');
    console.log(`[debug] onProjectTypeClick value: ${value}`);
  };

  const onShareYourLocationButtonClick = () => {
    try {
      navigator.geolocation.getCurrentPosition(
        onGetCurrentPositionSuccess,
        onGetCurrentPositionError
      );
      setShareYourLocationButtonIsLoading(true);
      setShareYourLocationButtonIsDisabled(true);
    } catch (error) {
      setShareYourLocationButtonIsLoading(false);
      setShareYourLocationButtonNegativeMessage(
        'There was an error with sharing your location. Please try again later.'
      );
      updateTriggerCarouselResize();
    }
  };

  const onGetCurrentPositionSuccess = async geolocationPosition => {
    // DEBUGGING
    // console.log(`[debug] onGetCurrentPositionSuccess called`);

    try {
      // GET LOCATION DATA FROM GENONAMES DATABASE
      const geoNamesResponse = await axios.get(
        `https://secure.geonames.org/countrySubdivisionJSON?lat=${geolocationPosition.coords.latitude}&lng=${geolocationPosition.coords.longitude}&username=raulbodhi`
      );
      updateLocationData('countryCode', geoNamesResponse.data.countryCode);
      updateLocationData('countryName', geoNamesResponse.data.countryName);
      updateLocationData('stateProvinceCode', geoNamesResponse.data.adminCode1);
      updateLocationData('stateProvinceName', geoNamesResponse.data.adminName1);
      updateLocationData('sharedLocation', true);
      setShareYourLocationButtonIsLoading(false);
      setShareYourLocationButtonPositiveMessage('The fields have been filled out below!');
      updateTriggerCarouselResize();

      // DEBUGGING
      // console.log(`[debug] onGetCurrentPositionSuccess geolocationPosition:`);
      // console.log(geolocationPosition);
      console.log(`[debug] onGetCurrentPositionSuccess geoNamesResponse.data:`);
      console.log(geoNamesResponse.data);
    } catch (error) {
      setShareYourLocationButtonIsLoading(false);
      setShareYourLocationButtonNegativeMessage(
        'There was an error with sharing your location. Please try again later.'
      );
      updateTriggerCarouselResize();

      // DEBUGGING
      // console.log(`[debug] onGetCurrentPositionSuccess error:`);
      // console.log(error)
    }
  };

  const onGetCurrentPositionError = geolocationPositionError => {
    setShareYourLocationButtonIsLoading(false);
    setShareYourLocationButtonNegativeMessage(
      'There was an error with sharing your location. Please try again later.'
    );
    updateTriggerCarouselResize();

    // DEBUGGING
    // console.log(`[debug] onGetCurrentPositionError called`);
    // console.log(`[debug] onGetCurrentPositionError geolocationPositionError:`);
    // console.log(geolocationPositionError);
  };

  const onDropdownClick = () => {
    updateTriggerCarouselResize();
  };

  const onDropdownBlur = () => {
    updateTriggerCarouselResize();
  };

  const onCountryOptionFocus = (countryCode, countryName) => {
    // DEBUGGING
    console.log(`[debug] onCountryOptionFocus called`);

    updateLocationData('countryCode', countryCode);
    updateLocationData('countryName', countryName);
  };

  const onStateProvinceOptionFocus = (stateProvinceCode, stateProvinceName) => {
    // DEBUGGING
    console.log(`[debug] onStateProvinceOptionFocus called`);

    updateLocationData('stateProvinceCode', stateProvinceCode);
    updateLocationData('stateProvinceName', stateProvinceName);
  };

  const onNameInputChange = e => {
    updateContactData('name', e.target.value);
  };

  const onCompanyInputChange = e => {
    updateContactData('company', e.target.value);
  };

  const onEmailInputChange = e => {
    updateContactData('email', e.target.value);
  };

  const onPhoneNumberInputChange = e => {
    updateContactData('phoneNumber', e.target.value);
  };

  const onBackButtonClick = () => {
    const newSelectedItem = selectedItem - 1;
    setSelectedItem(newSelectedItem);
  };

  const onNextButtonClick = () => {
    const newSelectedItem = selectedItem + 1;
    setSelectedItem(selectedItem + 1);
    setNextButtonIsDisabled(true);
  };

  const onSubmitButtonClick = async () => {
    // DEBUGGING
    console.log('[debug] onSubmitButtonClick called');

    sendToFirebase();
    sendToSalesforce();
  };

  const onPreferredContactMethodOptionFocus = (value, displayValue) => {
    // DEBUGGING
    console.log(`[debug] onPreferredContactMethodOptionFocus called`);

    updateContactData('preferredContactMethod', value);
    updateContactData('preferredContactMethodName', displayValue);
  };

  // HELPER FUNCTIONS
  const updateTriggerCarouselResize = () => {
    // THIS CODE MAY BE WEIRD BUT THE CHAIN OF TIMEOUT FUNCTIONS IS USED TO UPDATE SOME STATE TO FORCE THE CAROUSEL TO RESIZE DEPENDING ON THE CURRENT SLIDE'S CONTENT
    setTriggerCarouselResize(Math.random());
    setTimeout(() => {
      setTriggerCarouselResize(Math.random());
      setTimeout(() => {
        setTriggerCarouselResize(Math.random());
        setTimeout(() => {
          setTriggerCarouselResize(Math.random());
          setTimeout(() => {
            setTriggerCarouselResize(Math.random());
            setTimeout(() => {
              setTriggerCarouselResize(Math.random());
              setTimeout(() => {
                setTriggerCarouselResize(Math.random());
                setTimeout(() => {
                  setTriggerCarouselResize(Math.random());
                }, 100);
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 100);
  };

  const updateFormData = (property, value) => {
    formData[property] = value;
    const newFormData = {...formData};
    setFormData(newFormData);

    // DEBUGGING
    // console.log('[debug] updateFormData called');
    // console.log(`[debug] updateFormData newFormData:`);
    // console.log(newFormData);
  };

  const updateLocationData = (property, value) => {
    formData.locationData[property] = value;
    const newFormData = {...formData};
    setFormData(newFormData);

    // DEBUGGING
    // console.log('[debug] updateLocationData called');
    // console.log(`[debug] updateLocationData newFormData:`);
    // console.log(newFormData);
    console.log('[debug] updateLocationData, stateProvinceOptions:');
    console.log(stateProvinceOptions);
    console.log('[debug] updateLocationData, formData.locationData.countryCode:');
    console.log(formData.locationData.countryCode);
    console.log('[debug] updateLocationData, ');
  };

  const updateContactData = (property, value) => {
    formData.contactData[property] = value;
    const newFormData = {...formData};
    setFormData(newFormData);

    // DEBUGGING
    console.log('[debug] updateFormData called');
    console.log(`[debug] updateFormData newFormData:`);
    console.log(newFormData);
  };

  const sendToFirebase = async () => {
    formData.ts = Date.now();
    formData.tsString = new Date().toString();

    try {
      setSelectedItem(selectedItem + 1);
      const response = await createRef(COLLECTION_LEADS).add(formData);

      // DEBUGGING
      console.log('Document written with ID: ', response.id);
    } catch (error) {
      // DEBUGGING
      console.log('[debug] onSubmitButtonClick error');
      console.log(error);
    }
  };

  const sendToSalesforce = () => {
    salesforceSubmitButtonRef.current.click();
  };

  // SET LOCATION OPTIONS
  const countryOptions = [];
  const stateProvinceOptions = {};
  const otherOption = {
    other: {key: 'other', value: 'other', displayValue: 'Other..'},
  };
  locationData.forEach(country => {
    countryOptions.push({
      key: country.countryShortCode,
      value: country.countryShortCode,
      displayValue: country.countryName,
    });

    stateProvinceOptions[country.countryShortCode] = [];
    country.regions.forEach(region => {
      stateProvinceOptions[country.countryShortCode].push({
        key: region.shortCode,
        value: region.shortCode,
        displayValue: region.name,
      });
      stateProvinceOptions[country.countryShortCode].other = {...otherOption.other};
    });
  });

  // SET CONTACT OPTIONS
  const preferredContactMethodOptions = [
    {
      key: 'email',
      value: 'email',
      displayValue: 'Email',
    },
    {
      key: 'phone',
      value: 'phone',
      displayValue: 'Phone',
    },
  ];

  // WHEN SHARE YOUR LOCATION IS CLICKED
  let shareYourLocationButtonText = 'Share Your Location';
  let shareYourLocationButtonClassName = 'request-main-carousel-slide-main-location-button';
  if (shareYourLocationButtonIsLoading) {
    shareYourLocationButtonText = 'Loading...';
  }
  if (shareYourLocationButtonIsDisabled) {
    shareYourLocationButtonClassName +=
      ' request-main-carousel-slide-main-location-button--isDisabled';
  }

  // NEXT AND SUBMIT BUTTON CLASS NAME
  let nextButtonClassName = 'request-main-buttons-item request-main-buttons-item--variant-primary';
  let submitButtonClassName =
    'request-main-buttons-item request-main-buttons-item--variant-primary';
  if (nextButtonIsDisabled) {
    nextButtonClassName += ' request-main-buttons-item request-main-buttons-item--isDisabled';
  }
  if (submitButtonIsDisabled) {
    submitButtonClassName += ' request-main-buttons-item request-main-buttons-item--isDisabled';
  }

  // RENDER
  return (
    <div id="request-demo" className="request">
      <div className="request-main">
        {/* CAROUSEL */}
        <Carousel
          className="request-main-carousel"
          dynamicHeight={true}
          selectedItem={selectedItem}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
        >
          {/* PROJECT TYPE SLIDE */}
          <div className="request-main-carousel-slide">
            {/* HEAD */}
            <div className="request-main-carousel-slide-head">
              <div className="request-main-carousel-slide-head-heading">
                <Heading importance={3} lineHeight="1.5em" size={3}>
                  Request a Demo
                </Heading>
              </div>
              <div className="request-main-carousel-slide-head-divider" />
              <div className="request-main-carousel-slide-head-paragraph">
                <Paragraph lineHeight="1.5em" size={2}>
                  {requestContent.projectType.title}
                </Paragraph>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div className="request-main-carousel-slide-paragraph">
              <Paragraph size={3} lineHeight="1.5em" textAlign="center">
                {requestContent.projectType.description}
              </Paragraph>
            </div>
            {/* MAIN */}
            <div className="request-main-carousel-slide-main">
              <div className="request-main-carousel-slide-main-project-types">
                {requestContent.projectType.options.map((option, index) => {
                  // CLASS NAME
                  let className = 'request-main-carousel-slide-main-project-types-item';
                  if (formData.projectType === option.value) {
                    className += ' request-main-carousel-slide-main-project-types-item--isSelected';
                  }

                  // RENDER
                  return (
                    <div
                      className={className}
                      key={index}
                      onClick={() => onProjectTypeClick(option.value)}
                    >
                      <img
                        className="request-main-carousel-slide-main-project-types-item-image"
                        src={option.imagePath}
                      />
                      <div className="request-main-carousel-slide-main-project-types-item-paragraph">
                        <Paragraph size={3} lineHeight="1.5em">
                          {option.displayValue}
                        </Paragraph>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* PROFESSION SLIDE */}
          <div className="request-main-carousel-slide">
            {/* HEAD */}
            <div className="request-main-carousel-slide-head">
              <div className="request-main-carousel-slide-head-heading">
                <Heading importance={3} lineHeight="1.5em" size={3}>
                  Request a Demo
                </Heading>
              </div>
              <div className="request-main-carousel-slide-head-divider" />
              <div className="request-main-carousel-slide-head-paragraph">
                <Paragraph lineHeight="1.5em" size={2}>
                  {requestContent.profession.title}
                </Paragraph>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div className="request-main-carousel-slide-paragraph">
              <Paragraph size={3} lineHeight="1.5em">
                {requestContent.profession.description}
              </Paragraph>
            </div>
            {/* MAIN */}
            <div className="request-main-carousel-slide-main">
              <div className="request-main-carousel-slide-main-professions">
                {requestContent.profession.options.map((option, index) => {
                  // CLASS NAME
                  let className = 'request-main-carousel-slide-main-professions-item-button';
                  if (formData.profession === option.value) {
                    className +=
                      ' request-main-carousel-slide-main-professions-item-button--isSelected';
                  }

                  // RENDER
                  return (
                    <div className="request-main-carousel-slide-main-professions-item" key={index}>
                      <div className={className} onClick={() => onProfessionClick(option.value)}>
                        {option.displayValue}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* LOCATION SLIDE */}
          <div className="request-main-carousel-slide">
            {/* HEAD */}
            <div className="request-main-carousel-slide-head">
              <div className="request-main-carousel-slide-head-heading">
                <Heading importance={3} lineHeight="1.5em" size={3}>
                  Request a Demo
                </Heading>
              </div>
              <div className="request-main-carousel-slide-head-divider" />
              <div className="request-main-carousel-slide-head-paragraph">
                <Paragraph lineHeight="1.5em" size={2}>
                  {requestContent.location.title}
                </Paragraph>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div className="request-main-carousel-slide-paragraph">
              <Paragraph size={3} lineHeight="1.5em">
                {requestContent.profession.description}
              </Paragraph>
            </div>
            {/* MAIN */}
            <div className="request-main-carousel-slide-main">
              <div className="request-main-carousel-slide-main-location">
                {/* SHARE YOUR LOCATION BUTTON */}
                {/* <div
                  className={shareYourLocationButtonClassName}
                  tabIndex={0}
                  onClick={onShareYourLocationButtonClick}
                >
                  <div className="request-main-carousel-slide-main-location-button-icon">
                    <Icon name="map-marker-alt-solid" size={16} />
                  </div>
                  {shareYourLocationButtonText}
                </div> */}
                {/* SHARE YOUR LOCATION BUTTON POSITIVE MESSAGE*/}
                {shareYourLocationButtonPositiveMessage && (
                  <div className="request-main-carousel-slide-main-location-positive-message">
                    <Paragraph color="inherit" lineHeigh="1.5em" size={4} textAlign="center">
                      {shareYourLocationButtonPositiveMessage}
                    </Paragraph>
                  </div>
                )}
                {/* SHARE YOUR LOCATION BUTTON NEGATIVE MESSAGE*/}
                {shareYourLocationButtonNegativeMessage && (
                  <div className="request-main-carousel-slide-main-location-negative-message">
                    <Paragraph color="inherit" lineHeigh="1.5em" size={4} textAlign="center">
                      {shareYourLocationButtonNegativeMessage}
                    </Paragraph>
                  </div>
                )}

                {/* <div className="request-main-carousel-slide-main-location-paragraph">
                  <Paragraph linHeight="1.5em" size={4} textAlign="center">
                    or enter manually
                  </Paragraph>
                </div> */}

                {/* COUNTRY DROPDOWN */}
                <div
                  className="request-main-carousel-slide-main-location-dropdown"
                  tabIndex={0}
                  onClick={onDropdownClick}
                  onBlur={onDropdownBlur}
                >
                  <div className="request-main-carousel-slide-main-location-dropdown-main">
                    {formData.locationData.countryName ? (
                      <div className="request-main-carousel-slide-main-location-dropdown-main-value">
                        {formData.locationData.countryName}
                      </div>
                    ) : (
                      <div className="request-main-carousel-slide-main-location-dropdown-main-placeholder">
                        Select a country..
                      </div>
                    )}
                    <div className="request-main-carousel-slide-main-location-dropdown-main-icon">
                      <Icon name="caret-down-solid" size="16px" />
                    </div>
                  </div>
                  <div className="request-main-carousel-slide-main-location-dropdown-options">
                    {countryOptions.map((option, index) => (
                      <div
                        className="request-main-carousel-slide-main-location-dropdown-options-item"
                        key={index}
                        tabIndex={0}
                        onFocus={() => onCountryOptionFocus(option.value, option.displayValue)}
                      >
                        {option.displayValue}
                      </div>
                    ))}
                  </div>
                </div>

                {/* STATE/PROVINCE DROPDOWN */}
                {stateProvinceOptions[formData.locationData.countryCode] && (
                  <div
                    className="request-main-carousel-slide-main-location-dropdown"
                    tabIndex={0}
                    onClick={onDropdownClick}
                    onBlur={onDropdownBlur}
                  >
                    <div className="request-main-carousel-slide-main-location-dropdown-main">
                      {formData.locationData.stateProvinceName ? (
                        <div className="request-main-carousel-slide-main-location-dropdown-main-value">
                          {formData.locationData.stateProvinceName}
                        </div>
                      ) : (
                        <div className="request-main-carousel-slide-main-location-dropdown-main-placeholder">
                          Select a state/provice...
                        </div>
                      )}
                      <div className="request-main-carousel-slide-main-location-dropdown-main-icon">
                        <Icon name="caret-down-solid" size="16px" />
                      </div>
                    </div>
                    <div className="request-main-carousel-slide-main-location-dropdown-options">
                      {stateProvinceOptions[formData.locationData.countryCode].map(
                        (option, index) => (
                          <div
                            className="request-main-carousel-slide-main-location-dropdown-options-item"
                            key={index}
                            tabIndex={0}
                            onFocus={() =>
                              onStateProvinceOptionFocus(option.value, option.displayValue)
                            }
                          >
                            {option.displayValue}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* CONTACT SLIDE */}
          <div className="request-main-carousel-slide">
            {/* HEAD */}
            <div className="request-main-carousel-slide-head">
              <div className="request-main-carousel-slide-head-heading">
                <Heading importance={3} lineHeight="1.5em" size={3}>
                  Request a Demo
                </Heading>
              </div>
              <div className="request-main-carousel-slide-head-divider" />
              <div className="request-main-carousel-slide-head-paragraph">
                <Paragraph lineHeight="1.5em" size={2}>
                  {requestContent.contact.title}
                </Paragraph>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div className="request-main-carousel-slide-paragraph">
              <Paragraph size={3} lineHeight="1.5em">
                {requestContent.profession.description}
              </Paragraph>
            </div>
            {/* MAIN */}
            <div className="request-main-carousel-slide-main">
              <div className="request-main-caorusel-slide-main-contact">
                <input
                  className="request-main-caorusel-slide-main-contact-input"
                  placeholder="Name"
                  value={formData.contactData.name}
                  onChange={onNameInputChange}
                />
                <input
                  className="request-main-caorusel-slide-main-contact-input"
                  placeholder="Company"
                  value={formData.contactData.company}
                  onChange={onCompanyInputChange}
                />
                <input
                  className="request-main-caorusel-slide-main-contact-input"
                  placeholder="Email"
                  type="email"
                  value={formData.contactData.email}
                  onChange={onEmailInputChange}
                />
                <input
                  autoComplete="tel"
                  className="request-main-caorusel-slide-main-contact-input"
                  placeholder="Phone Number"
                  type="tel"
                  value={formData.contactData.phoneNumber}
                  onChange={onPhoneNumberInputChange}
                />

                {/* PREFERRED CONTACT METHOD DROPDOWN */}
                <div
                  className="request-main-carousel-slide-main-contact-dropdown"
                  tabIndex={0}
                  onClick={onDropdownClick}
                  onBlur={onDropdownBlur}
                >
                  <div className="request-main-carousel-slide-main-contact-dropdown-main">
                    {formData.contactData.preferredContactMethod ? (
                      <div className="request-main-carousel-slide-main-contact-dropdown-main-value">
                        {formData.contactData.preferredContactMethodName}
                      </div>
                    ) : (
                      <div className="request-main-carousel-slide-main-contact-dropdown-main-placeholder">
                        Select a preferred contact method...
                      </div>
                    )}
                    <div className="request-main-carousel-slide-main-contact-dropdown-main-icon">
                      <Icon name="caret-down-solid" size="16px" />
                    </div>
                  </div>
                  <div className="request-main-carousel-slide-main-contact-dropdown-options">
                    {preferredContactMethodOptions.map((option, index) => (
                      <div
                        className="request-main-carousel-slide-main-contact-dropdown-options-item"
                        key={index}
                        tabIndex={0}
                        onFocus={() =>
                          onPreferredContactMethodOptionFocus(option.value, option.displayValue)
                        }
                      >
                        {option.displayValue}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CONFIRM SLIDE */}
          <div className="request-main-carousel-slide">
            <div className="requiest-main-carousel-slide-main">
              <div className="requiest-main-carousel-slide-main-configm">
                <Icon name="check-solid" size={'32px'} />
                <Heading importance={3} lineHeight={'1.5em'} size={4} textAlign="center">
                  Thank You
                </Heading>
                <Paragraph lineHeight={'1.5em'} size={4} textAlign="center">
                  We've recieved your information and will be in touch soon!
                </Paragraph>
              </div>
            </div>
          </div>
        </Carousel>
        {/* BACK, NEXT, SUBMIT BUTTONS */}
        <div className="request-main-buttons">
          {backButtonIsVisible && (
            <div
              className="request-main-buttons-item request-main-buttons-item--variant-outlined"
              onClick={onBackButtonClick}
            >
              Back
            </div>
          )}
          {nextButtonIsVisible && (
            <div className={nextButtonClassName} onClick={onNextButtonClick}>
              Next
            </div>
          )}
          {submitButtonIsVisible && (
            <div className={submitButtonClassName} onClick={onSubmitButtonClick}>
              Submit
            </div>
          )}
        </div>
      </div>

      {/* SALESFORCE FORM */}
      <form
        className="request-hidden"
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
      >
        <input type="hidden" name="oid" value={salesforceConfiguration.webToLeadOid} />

        <input type="hidden" name="retURL" value={salesforceConfiguration.webToLeadReturnURL} />

        {/* PROJECT TYPE */}
        <input
          id="00N5e00000J2uAt"
          maxlength="40"
          name="00N5e00000J2uAt"
          size="20"
          type="text"
          value={formData.projectType}
        />

        {/* PROFESSION */}
        <input
          id="title"
          maxlength="40"
          name="title"
          size="20"
          type="text"
          value={formData.profession}
        />

        {/* COUNTRY CODE */}
        <input
          id="00N5e00000J2wxz"
          maxlength="40"
          name="00N5e00000J2wxz"
          size="20"
          type="text"
          value={formData.locationData.countryCode}
        />

        {/* COUNTRY NAME */}
        <input
          id="00N5e00000J2x2C"
          maxlength="40"
          name="00N5e00000J2x2C"
          size="20"
          type="text"
          value={formData.locationData.countryName}
        />

        {/* STATE OR PROVINCE CODE */}
        <input
          id="00N5e00000J2wxk"
          maxlength="20"
          name="00N5e00000J2wxk"
          size="20"
          type="text"
          value={formData.locationData.stateProvinceCode}
        />

        {/* STATE OR PROVINCE NAME */}
        <input
          id="00N5e00000J2wxp"
          maxlength="20"
          name="00N5e00000J2wxp"
          size="20"
          type="text"
          value={formData.locationData.stateProvinceName}
        />

        {/* SHARED LOCATION */}
        <input
          id="00N5e00000J2x2M"
          maxlength="20"
          name="00N5e00000J2x2M"
          size="20"
          type="text"
          value={formData.locationData.sharedLocation}
        />

        {/* FIRST NAME */}
        <input
          id="first_name"
          maxlength="40"
          name="first_name"
          size="20"
          type="text"
          value={formData.contactData.name}
        />

        {/* LAST NAME */}
        <input id="last_name" maxlength="40" name="last_name" size="20" type="text" value="" />

        {/* COMPANY */}
        <input
          id="company"
          maxlength="40"
          name="company"
          size="20"
          type="text"
          value={formData.contactData.company}
        />

        {/* FULL NAME */}
        <input
          id="00N5e00000J2uAj"
          maxlength="40"
          name="00N5e00000J2uAj"
          size="20"
          type="text"
          value={formData.contactData.name}
        />

        {/* EMAIL */}
        <input
          id="email"
          maxlength="80"
          name="email"
          size="20"
          type="text"
          value={formData.contactData.email}
        />

        {/* PHONE */}
        <input
          id="phone"
          maxlength="80"
          name="phone"
          size="20"
          type="text"
          value={formData.contactData.phoneNumber}
        />

        {/* PREFERRED CONTACT METHOD */}
        <input
          id="00N5e00000J2uAy"
          maxlength="40"
          name="00N5e00000J2uAy"
          size="20"
          type="text"
          value={formData.contactData.preferredContactMethod}
        />

        {/* FORM NAME */}
        <input
          id="00N5e00000XON2R"
          maxlength="64"
          name="00N5e00000XON2R"
          size="20"
          type="text"
          value="requestADemo"
        />

        {/* SUBMIT BUTTON */}
        <input name="submit" ref={salesforceSubmitButtonRef} type="submit" />
      </form>
    </div>
  );
};
