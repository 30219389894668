import React from 'react';
import {navigate} from 'gatsby';
import {footer as footerContent} from '../../../helpers/content';
import {Heading, Icon, Paragraph} from '../../ui';
import './footer.css';

export const Footer = () => {
  // EVENT HANDLERS
  const onSocialMediaClick = url => {
    console.log(url);
    window.open(url);
  };

  //RENDER
  return (
    <div className="footer">
      <div className="footer-main">
        <img className="footer-main-brand" src={footerContent.brandImagePath} />
        <div className="footer-main-list">
          <div className="footer-main-list-item">
            {/* <div className="footer-main-list-item-heading">
                            <Heading
                                color="#inherit"
                                display="inline"
                                lineHeight="1.2em"
                                importance={4}
                                size={6}
                            >
                                CONTACT US
                            </Heading>
                        </div> */}
            <div className="footer-main-list-item-contact">
              {footerContent.contacts.map((contact, index) => (
                <a className="footer-main-list-item-contact-link" href={contact.url} key={index}>
                  <span className="footer-main-list-item-contact-link-label">
                    <Heading
                      color="inherit"
                      display="inline"
                      lineHeight="1.5em"
                      importance={4}
                      size={6}
                    >
                      {contact.label}
                    </Heading>
                  </span>
                  <span className="footer-main-list-item-contact-link-value">
                    <Paragraph color="inherit" display="inline" lineHeight="1.5em" size={6}>
                      {contact.value}
                    </Paragraph>
                  </span>
                </a>
              ))}
            </div>
          </div>
          <div className="footer-main-list-item">
            {/* <div className="footer-main-list-item-heading">
                            <Heading
                                color="inherit"
                                display="inline"
                                lineHeight="1.2em"
                                importance={4}
                                size={6}
                            >
                                FOLLOW US
                            </Heading>
                        </div> */}
            <div className="footer-main-list-item-social-media">
              {footerContent.socialMedias.map((socialMedia, index) => (
                <div
                  className="footer-main-list-item-social-media-item"
                  onClick={() => onSocialMediaClick(socialMedia.url)}
                >
                  <Icon name={socialMedia.iconName} color="#ffffff" />
                </div>
              ))}
            </div>
          </div>
          <div className="footer-main-list-item">
            <div className="footer-main-list-item-copyright">
              <Paragraph color="inherit" lineHeight="1.5em" size={6} textAlign="inherit">
                {footerContent.copyright}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
