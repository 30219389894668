// import config from '../config/config.json'
// export const salesforceAccountURL = config.salesforceAccountURL
// export const salesforceConsumerKey = config.salesforceConsumerKey
// export const salesforceConsumerSecret = config.salesforceConsumerSecret
// export const salesforceAccessToken = config.salesforceAccessToken
export const COLLECTION_LEADS = 'leads';

export const projectTypeOptions = [
  {key: 'commercial', name: 'Commercial'},
  {key: 'hospitality', name: 'Hospitality'},
  {key: 'residential', name: 'Residential'},
];

export const professionOptions = {
  commercial: [
    {key: 'owner', name: 'Owner'},
    {key: 'developer', name: 'Developer'},
    {key: 'architect', name: 'Architect'},
    {key: 'designer', name: 'Designer'},
    {key: 'systemintegrator', name: 'System Integrator'},
    {key: 'other', name: 'Other'},
  ],
  hospitality: [
    {key: 'owner', name: 'Owner'},
    {key: 'developer', name: 'Developer'},
    {key: 'architect', name: 'Architect'},
    {key: 'designer', name: 'Designer'},
    {key: 'systemintegrator', name: 'System Integrator'},
    {key: 'other', name: 'Other'},
  ],
  residential: [
    {key: 'owner', name: 'Owner'},
    {key: 'developer', name: 'Developer'},
    {key: 'architect', name: 'Architect'},
    {key: 'designer', name: 'Designer'},
    {key: 'systemintegrator', name: 'System Integrator'},
    {key: 'other', name: 'Other'},
  ],
};

export const contactMethodOptions = [
  {key: 'email', value: 'email', text: 'E-mail'},
  {key: 'phone', value: 'phone', text: 'Phone'},
];

export const defaultFormData = {
  projectType: 'residential',
  profession: 'designer',
  locationData: {
    countryCode: 'US',
    countryName: 'United States',
    sharedLocation: true,
    stateProvinceCode: 'FL',
    stateProvinceName: 'Florida',
  },
  contactData: {
    email: 'raul@thinksimple.com',
    name: 'Raul La Rosa',
    phoneNumber: '+13057533720',
    preferredContactMethod: 'email',
  },
};
