import React, {useState, useEffect} from 'react';
import {navigate} from 'gatsby';
import {Transition} from 'react-transition-group';
import {useScroll, useWindowSize} from '../../../hooks';
import {Icon} from '../../ui';
import './navigation-bar.css';
import {navigationBar as navigationBarContent} from '../../../helpers/content';

export const NavigationBar = props => {
  // PROPS
  const {navigationTopBarIsInitiallyPartiallyTransparent = false} = props;

  // STATE
  const [rightBarIsVisible, setRightBarIsVisible] = useState(false);

  // USE SCROLL HOOK
  const scroll = useScroll();

  // EVENT HANDLERS
  const onBrandClick = () => {
    navigate('/');
  };

  const onBarsClick = () => {
    setRightBarIsVisible(true);
  };

  const onTopBarLinkClick = path => {
    if (path) {
      navigate(path);
    }

    // DEBUGGING
    //console.log(`[debug]: ${path}`)
  };

  const onRightBarCloseClick = () => {
    setRightBarIsVisible(false);
  };

  const onRightBarBackgroundOnclick = () => {
    setRightBarIsVisible(false);
  };

  const onRightBarLinkClick = path => {
    if (path) {
      navigate(path);
      setRightBarIsVisible(false);
    }

    // DEBUGGING
    // console.log(`[debug]: ${path}`)
  };

  // TOP BAR MAIN STYLE
  let navigationTopBarStyle;
  if (scroll.y > 0) {
    navigationTopBarStyle = {
      backgroundColor: '#ffffff',
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
    };
  }
  // RIGHT BAR MAIN STYLE
  const rightBarMainStyles = {
    entering: {transform: 'translateX(0%)'},
    entered: {transform: 'translateX(0%)'},
    exiting: {transform: 'translateX(100%)'},
    exited: {transform: 'translateX(100%)'},
  };

  const rightBarBackgroundStyles = {
    entering: {
      display: 'block',
      opacity: 0,
    },
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {
      display: 'none',
      opacity: 0,
    },
  };

  // CLASSNAMES
  const navigationTopBarClassName = `navigation-top-bar is-initially-partially-transparent--${navigationTopBarIsInitiallyPartiallyTransparent}`;

  return (
    <div className="navigation-bar">
      {/* TOP BAR */}
      <div className="navigation-top-bar-buffer" />
      <div className={navigationTopBarClassName} style={{...navigationTopBarStyle}}>
        <div className="navigation-top-bar-main">
          <div className="navigation-top-bar-main-brand" onClick={onBrandClick}>
            <img
              className="navigation-top-bar-main-brand-image"
              src="/images/logo-bodhi-black.png"
            />
          </div>
          <div className="navigation-top-bar-main-links">
            {navigationBarContent.links.map(link => (
              <div
                className={`${link.className} navigation-top-bar-main-links-item`}
                key={link.key}
                tabIndex={0}
              >
                <div
                  className={`${link.valueClassName} navigation-top-bar-main-links-item-value`}
                  onClick={() => onTopBarLinkClick(link.path)}
                >
                  {link.value}
                </div>
                {link.sublinks && (
                  <div className="navigation-top-bar-main-links-item-sublinks">
                    {link.sublinks.map(sublink => (
                      <div
                        className="navigation-top-bar-main-links-item-sublinks-item"
                        key={sublink.key}
                        onClick={() => onTopBarLinkClick(sublink.path)}
                      >
                        {sublink.value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="navigation-top-bar-main-bars-button" onClick={onBarsClick}>
            <Icon size="32px" name="bars-solid" />
          </div>
        </div>
      </div>

      {/* RIGHT BAR */}
      <Transition in={rightBarIsVisible} timeout={500}>
        {transitionState => (
          <div className="navigation-right-bar">
            <div
              className="navigation-right-bar-background"
              style={rightBarBackgroundStyles[transitionState]}
              onClick={onRightBarBackgroundOnclick}
            />
            <div className="navigation-right-bar-main" style={rightBarMainStyles[transitionState]}>
              <div className="navigation-right-bar-main-links">
                <div className="navigation-right-bar-main-links-item">
                  <div
                    className="navigation-right-bar-main-links-item-value"
                    tabIndex={0}
                    onClick={onRightBarCloseClick}
                  >
                    <div className="navigation-right-bar-main-links-item-value-icon">
                      <Icon color="#ffffff" name="times-solid" size="100%" />
                    </div>
                    Close
                  </div>
                </div>
                {navigationBarContent.links.map(link => (
                  <div key={link.key} className="navigation-right-bar-main-links-item">
                    <div
                      className="navigation-right-bar-main-links-item-value"
                      tabIndex={0}
                      onClick={() => onRightBarLinkClick(link.path)}
                    >
                      {link.value}
                    </div>
                    {link.sublinks && (
                      <div className="navigation-right-bar-main-links-item-sublinks">
                        {link.sublinks.map((sublink, index) => (
                          <div
                            className="navigation-right-bar-main-links-item-sublinks-item"
                            key={index}
                            onClick={() => onRightBarLinkClick(sublink.path)}
                          >
                            {sublink.value}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};
