import {useEffect, useState} from 'react';

export const useScroll = () => {
  const [scroll, setScroll] = useState({
    x: undefined,
    y: undefined,
  });

  useEffect(() => {
    const onScroll = () => {
      setScroll({
        x: window.scrollX,
        y: window.scrollY,
      });
    };

    window.addEventListener('scroll', onScroll);

    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scroll;
};
